import axios from 'axios'
import { api } from '../assets/API/api';
import getSessionStorage from './getSessionStorage';



export default async function getActivePlans(user_id) {
    let temp = getSessionStorage();
    try {
        const { data } = await axios.get(`${api}/getValidPlans/?tokenMail=${temp?.email}&id=${user_id}`);
        return data;
    } catch (error) {
        return [];
    }
}