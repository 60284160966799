import React from "react";
// import Nav from 'react-bootstrap/Nav'
import Table from "react-bootstrap/Table";

// import switch_01 from "../../assets/images/switch_01.png"
// import image_consumption from "../../assets/images/image_consumption.png"
import view_icon from "../../assets/images/view_icon.png";
import search_result_icon from "../../assets/images/search_result_icon.png";
import FavoriteSearches_icon from "../../assets/images/FavoriteSearches_icon.png";

import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import { myContext } from "../../App";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
// import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
// import { PieChart } from 'react-minimal-pie-chart';
import Timeline from "../Timeline/Timeline";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { RotatingLines } from 'react-loader-spinner'
import no_image from "../../assets/images/no_image.png";
import Loader, { LoaderTwo } from "../Loader/loader";
import Graph from "./Graph";
import NoDataFound from "../NoDataFound/noDataFound";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

const MyActivityArea = ({ trial, trHistory }) => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [recentSearches, setRecentSearches] = st.recentSearches;
  const [recentFavSearches, setRecentFaVSearches] = st.recentFavSearches;
  const [search_id, setSearch_id] = st.search_id;
  const [searchParams, setSearchParams] = st.searchParams;
  const [displayArr, setDisplayArr] = st.displayArr;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [show, setShow] =
    useState(
      true
    ); /* used to toggle between my favorites and recent searches */
  const [users, setUsers] = useState([]);
  const [points, setPoints] = useState(); // points = purchased points
  const [usedPoints, setUsedPoints] = st.usedPoints;
  const [points2, setPoints2] = useState();
  // const [balance, setBalance] = st.balance;
  const [activePlans, setActivePlans] = st.activePlans;
  const [myBalance, setMyBalance] = st.myBalance;

  // const [addon, setaddon] = st.addon;
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [tr, setTr] = useState([]);

  const temp = getSessionStorage();

  const getAllUsers = async () => {
    setLoad(true);
    await axios
      .get(
        `${api}/getAllUsers/?tokenMail=${temp?.email}&email=${
          userInfo?.email || temp?.email
        }`
      )
      .then((res) => {
        // console.log(res.data);
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((f) => setLoad(false));
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }

    if (activePlans[0]?.no_of_users > 1) {
      getAllUsers();
    }
  }, [activePlans]);

  let hist = useHistory();

  const view = async (
    primary,
    first,
    second,
    third,
    fourth,
    fifth,
    search_id
  ) => {
    if (!temp?.user_id) {
      return;
    }

    setLoading(true);

    await setSearch_id(search_id);
    localStorage.setItem("search-id", JSON.stringify(search_id));

    await setSearchParams({
      primaryProfession: "",
      firstProfession: "",
      secondProfession: "",
      thirdProfession: "",
      fourthProfession: "",
      fifthProfession: "",
    });

    await setSearchParams({
      primaryProfession: primary,
      firstProfession: first,
      secondProfession: second,
      thirdProfession: third,
      fourthProfession: fourth,
      fifthProfession: fifth,
    });

    localStorage.setItem(
      "search-params",
      JSON.stringify({
        primaryProfession: primary,
        firstProfession: first,
        secondProfession: second,
        thirdProfession: third,
        fourthProfession: fourth,
        fifthProfession: fifth,
      })
    );

    await axios
      .post(`${api}/getResults?tokenMail=${temp?.email}`, {
        primaryProfession: primary,
        firstProfession: first,
        secondProfession: second,
        thirdProfession: third,
        fourthProfession: fourth,
        fifthProfession: fifth,
      })
      .then((res) => {
        // console.log(res.data)
        setDisplayArr(res.data);
        localStorage.setItem("main-array", JSON.stringify(res.data));
        hist.push("/SearchResults", hist.location.pathname);
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoading(false));
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }
    let today = new Date()?.toJSON();
    today = today?.slice(0, 10);
    setTr(trHistory?.filter((e) => e.purchase_date === today));
  }, [trHistory]);

  return (
    <div>
      {loading && <LoaderTwo />}
      <div className="MyActivityAreaCover">
        <h2>
          {activePlans[0]?.no_of_users > 1
            ? temp?.userType === "enterprise-user"
              ? `${temp?.companyName}${
                  temp?.companyName?.at(-1)?.toLowerCase() === "s" ? "'" : "'s"
                } Area`
              : "My Team's Area"
            : "My Activity Area"}
        </h2>
        <div className="MyActivityAreaCover">
          {activePlans[0]?.no_of_users > 1 ? (
            <div
              className="MyActivityAreaCoverTwoBoxInnerLeft"
              style={{ marginBottom: "25px" }}
            >
              <h1 style={{ fontSize: "21px", fontWeight: "600" }}>
                List of Users
              </h1>
              {load ? (
                <Loader />
              ) : (
                <>
                  <Table
                    className="MyActivityTable3 user-list-table load_animation"
                    datatype="user-list"
                    responsive
                    striped
                    style={{ borderBottom: "1px solid #c4c4c4" }}
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th style={{ textAlign: "center" }}>Email ID</th>
                        <th style={{ textAlign: "center" }}>Searches Used</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.length
                        ? users.map((el, idx) => {
                            return (
                              idx >= 0 &&
                              idx < 5 && (
                                <tr>
                                  <td className="fw-bold">
                                    <div className="viewDiv">
                                      <img
                                        src={el?.image_url || no_image}
                                        className=""
                                        loading="lazy"
                                        alt=""
                                      />
                                      {el?.firstName || "NA"}{" "}
                                      {el?.lastName || ""}
                                    </div>
                                  </td>
                                  <td className="">
                                    {el.role ? el.role : "NA"}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <span>{el.email}</span>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {commaInNumber(el?.points || 0)}
                                  </td>
                                  <td
                                    onClick={() => {
                                      hist.push("/PreviousSearchResults", {
                                        id: el?.user_id,
                                        prevUrl: "/AddUsers",
                                        subordinateFirstName: el?.firstName,
                                        subordinateLastName: el?.lastName,
                                      });
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span className="viewSpan">View</span>
                                  </td>
                                </tr>
                              )
                            );
                          })
                        : null}
                    </tbody>
                  </Table>

                  {!users?.length && (
                    <NoDataFound
                      text={"No Users Found"}
                      height={150}
                      margin={5}
                    />
                  )}
                </>
              )}
              <div className="buttonRow">
                <Link to="/AddUsers">
                  <Button className="search_result_button">
                    <img
                      src={search_result_icon}
                      className=""
                      loading="lazy"
                      alt=""
                    />
                    Full Users List
                  </Button>
                </Link>
              </div>
            </div>
          ) : null}

          <div className="row special-my-activity-area">
            <div className="col-lg-6">
              <div
                className="MyActivityAreaCoverTwoBoxInner"
                style={{ position: "relative" }}
              >
                {activePlans[0]?.no_of_users > 1 ? (
                  <Timeline />
                ) : (
                  <div
                    className="MyActivityAreaTop"
                    style={{ marginBottom: "68px" }}
                  >
                    <ul className="nav nav-tabs " id="myTab" role="tablist">
                      <li
                        className="nav-item MyActivityAreaTabLeft"
                        role="presentation"
                      >
                        <button
                          className={show ? "nav-link active" : "nav-link"}
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected={"true"}
                        >
                          {activePlans[0]?.no_of_users > 1
                            ? "Recent Searches"
                            : "My Recent Searches"}
                        </button>
                      </li>
                      <li
                        className="nav-item MyActivityAreaTabRight"
                        role="presentation"
                      >
                        <button
                          className={show ? "nav-link" : "nav-link active"}
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected={"false"}
                        >
                          {activePlans[0]?.no_of_users > 1
                            ? "Favourite Searches"
                            : "My Favourite Searches"}
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <Table striped className="MyActivityTable">
                          <thead>
                            <tr>
                              <th>Primary Searches</th>
                              <th className="text-center">Searches Used</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentSearches?.length
                              ? recentSearches.map((el, idx) => {
                                  return idx < 5 && idx >= 0 ? (
                                    <tr>
                                      <td>{el.primary_profession}</td>
                                      <td className="text-center">
                                        {el.points}
                                      </td>
                                      <td className="view">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={
                                            loading
                                              ? null
                                              : () => {
                                                  view(
                                                    el.primary_profession,
                                                    el.first_profession,
                                                    el.second_profession,
                                                    el.third_profession,
                                                    el.fourth_profession,
                                                    el.fifth_profession,
                                                    el.search_id
                                                  );
                                                }
                                          }
                                        >
                                          <img
                                            src={view_icon}
                                            className=""
                                            loading="lazy"
                                            alt=""
                                          />{" "}
                                          View
                                        </span>
                                      </td>
                                    </tr>
                                  ) : null;
                                })
                              : null}
                          </tbody>
                        </Table>
                        {!recentSearches?.length && (
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No Previous History!
                          </div>
                        )}
                        <div
                          className="buttonRow"
                          style={{ position: "absolute", bottom: "0" }}
                        >
                          <Link to="/PreviousSearchResults">
                            <Button className="search_result_button">
                              <img
                                src={search_result_icon}
                                className=""
                                loading="lazy"
                                alt=""
                              />
                              Previous Search Results
                            </Button>
                          </Link>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <Table striped className="MyActivityTable">
                          <thead>
                            <tr>
                              <th>Primary Searches</th>
                              <th className="text-center">Searches Used</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {recentFavSearches?.length
                              ? recentFavSearches.map((el, idx) => {
                                  return idx >= 0 && idx < 5 ? (
                                    <tr>
                                      <td>{el.primary_profession}</td>
                                      <td className="text-center">
                                        {el.points}
                                      </td>
                                      <td className="view2">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={
                                            loading
                                              ? null
                                              : () => {
                                                  view(
                                                    el.primary_profession,
                                                    el.first_profession,
                                                    el.second_profession,
                                                    el.third_profession,
                                                    el.fourth_profession,
                                                    el.fifth_profession,
                                                    el.search_id
                                                  );
                                                }
                                          }
                                        >
                                          <img
                                            src={view_icon}
                                            className=""
                                            loading="lazy"
                                            alt=""
                                          />{" "}
                                          View
                                        </span>
                                      </td>
                                    </tr>
                                  ) : null;
                                })
                              : null}
                          </tbody>
                        </Table>
                        {!recentFavSearches?.length && (
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No Favourites!
                          </div>
                        )}
                        <div
                          className="buttonRow"
                          style={{ position: "absolute", bottom: "0" }}
                        >
                          <Link to="/FavouriteSearches">
                            <Button className="FavoriteSearches_Button">
                              <img
                                src={FavoriteSearches_icon}
                                className=""
                                loading="lazy"
                                alt=""
                              />
                              Favorite Searches
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <Graph
                myBalance={myBalance}
                usedPoints={usedPoints}
                tr={tr}
                trial={trial}
                activePlans={activePlans}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyActivityArea;
