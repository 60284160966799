import React from "react";
import Layout from "../Layout/Layout";
//import { Button } from 'react-bootstrap'

import removeNotificatio from "../../assets/images/removeNotification.svg";
import saveNotification from "../../assets/images/saveNotification.svg";
import notiNotification from "../../assets/images/notiNotification.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { myContext } from "../../App";
import axios from "axios";
import { api } from "../../assets/API/api";
import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { getAllByAltText } from "@testing-library/react";
import Loader from "../Loader/loader";

const MyNotifications = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [authenticate, setAuthenticate] = st.authentication;
  const [history, setHistory] = useState([]);
  const [checked, setChecked] = useState([]);
  // const [selectedNoti, setSelectedNoti] = useState([]);
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();

  const selectAll = (e) => {
    let pState = [...history];
    if (e.target.checked) {
      pState.fill(true);
      setChecked(pState);
    } else {
      pState.fill(false);
      setChecked(pState);
    }
  };

  // Add/Remove checked item from list
  const handleCheck = (e, idx) => {
    let pState = [...checked];
    let pVal = pState[idx];
    pState.fill(false);
    pState[idx] = !pVal;
    setChecked(pState);
  };

  const getAllNotifications = async () => {
    setLoading(true);
    await axios
      .post(`${api}/getNotifications?tokenMail=${temp?.email}`, {
        userId: userInfo.id || temp.user_id,
      })
      .then((res) => {
        // console.log(res.data);
        setHistory(res.data);
        let pState = [];
        for (let i = 0; i < res.data?.length; i++) {
          pState.push(false);
        }
        setChecked(pState);
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoading(false));
  };

  const markRead = async () => {
    await axios
      .post(`${api}/getNotifications?tokenMail=${temp?.email}`, {
        userId: userInfo.id || temp.user_id,
        markRead: true,
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!temp.user_id || temp?.userType === "Company Head") {
      return;
    }

    getAllNotifications();
    markRead();
  }, []);

  const reload = (e) => {
    e.preventDefault();

    if (!temp.user_id) {
      return;
    }

    getAllNotifications();
    markRead();
  };

  const deleteNotification = (el) => {
    if (!temp.user_id) {
      return;
    }
    let ok = window.confirm("Are you sure to delete?");
    if (!ok) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/getNotifications?tokenMail=${temp?.email}`, {
        delete: true,
        notificationId: el?.notification_table_id,
      })
      .then((res) => {
        // console.log(res.data);
        getAllNotifications();
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoading(false));
  };

  const deleteAll = (e) => {
    e.preventDefault();

    if (!temp.user_id) {
      return;
    }
    let ok = window.confirm("Are you sure to delete all notifications?");
    if (!ok) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/getNotifications?tokenMail=${temp?.email}`, {
        deleteAll: true,
        userId: temp?.user_id,
      })
      .then((res) => {
        // console.log(res.data);
        getAllNotifications();
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoading(false));
  };

  // console.log(checked);
  // console.log(selectedNoti)

  return (
    <Layout>
      <div className="row notifications">
        <div className="col-xl-8 offset-xl-2">
          <div className="checkboxHead">
            <input
              style={{ cursor: "pointer" }}
              onChange={selectAll}
              type="checkbox"
              name="nofitication all"
              id="selectAll"
            />
            <label For="selectAll">Select All</label>
            <span
              onClick={reload}
              style={{ cursor: "pointer" }}
              className="reload"
            >
              Reload
            </span>
            <span className="actions">
              <span>
                <img
                  onClick={deleteAll}
                  style={{ cursor: "pointer" }}
                  src={removeNotificatio}
                  className=""
                  loading="lazy"
                />
              </span>
              {/* <span><img src={saveNotification} className='' loading='lazy' /></span> */}
              {/* <span><img src={notiNotification} className='' loading='lazy' /></span> */}
            </span>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="checkboxList load_animation">
              {!history?.length && (
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "1.2rem",
                    marginTop: "10%",
                    height: "111.5px",
                  }}
                >
                  No Notifications!
                </h3>
              )}
              {history?.map((el, idx) => {
                return (
                  <div name="temp" className="notificationItem">
                    <input
                      onChange={(e) => handleCheck(e, idx)}
                      checked={checked[idx]}
                      type="checkbox"
                      name="notification"
                      id={`noti00${idx + 1}`}
                    />
                    {/* <span className="time">12:44 PM</span> */}
                    <span className="time">
                      <span>{el.date?.slice(0, 10)}</span>
                    </span>
                    <span className="actions">
                      <span>
                        <img
                          onClick={() => deleteNotification(el)}
                          src={removeNotificatio}
                          className=""
                          loading="lazy"
                        />
                      </span>
                      {/* <span><img src={saveNotification} className='' loading='lazy' /></span> */}
                      {/* <span><img src={notiNotification} className='' loading='lazy' /></span> */}
                    </span>
                    <label for={`noti00${idx + 1}`}>
                      <h4>{el.header}</h4>
                      <p>{el.body}</p>
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MyNotifications;
