import {
  faCalendarAlt,
  faClock,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";
import Layout from "../Layout/Layout";
import Loader from "../Loader/loader";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

let arr = [];

export default function GiftCard() {
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();
  const [history, setHistory] = useState([]);
  // const [filter, setFilter] = useState([]);
  // const [year, setYear] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [redeem, setRedeem] = useState(false);
  const [code, setCode] = useState("");
  const [giftCardDetails, setGiftCardDetails] = useState(null);
  const [invalidGiftCard, setInvalidGiftCard] = useState(false);
  const [loadGift, setLoadGift] = useState(false);

  // let curYear = new Date();

  // curYear = parseInt(curYear?.getFullYear());

  const closeModal = () => {
    setRedeem(false);
    setGiftCardDetails(null);
    setInvalidGiftCard(false);
    setCode("");
    setLoadGift(false);
  };

  const getGiftCardTransactions = async () => {
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${api}/get-giftcard-transactions/?tokenMail=${temp?.email}&user_id=${temp?.user_id}`
      );
      setHistory(data);
      arr = data;
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const getFilterYear = () => {
  //     let a = parseInt(temp?.date_of_joining?.slice(0, 4));
  //     let b = curYear;
  //     for (let i = a; i <= b; i++) {
  //         filter.push(i);
  //     }
  //     setFilter(filter)
  // }

  const handleChange = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (val === "") {
      return setHistory(arr);
    }

    setHistory(
      arr.filter((e) =>
        e.gift_code?.toLowerCase()?.includes(val?.toLowerCase())
      )
    );
  };

  // const handleYearFilter = (e) => {
  //     e.preventDefault();
  //     setYear(e.target.value);

  //     if (e.target.value == "all") {
  //         setHistory(arr);
  //         return;
  //     }
  //     let val = e.target.value;
  //     setHistory(arr.filter(e => e.purchase_date?.includes(val)));
  // }

  const redeemGift = async (e) => {
    e.preventDefault();
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }

    if (!code) {
      return alert("Please enter a valid code!");
    }

    try {
      setLoadGift(true);
      const { data } = await axios.post(
        `${api}/redeem-gift-card/?tokenMail=${temp?.email}`,
        {
          user_id: temp?.user_id,
          code: code,
        }
      );
      // console.log(data);
      setGiftCardDetails(data?.data);
      setLoadGift(false);
      getGiftCardTransactions();
    } catch (error) {
      console.log(error.response.data);
      setInvalidGiftCard(true);
      setLoadGift(false);
    }
  };

  // console.log(giftCardDetails);

  const next = (e) => {
    e.preventDefault();

    if (pageNo > history?.length / 10) {
      return;
    }
    setPageNo((p) => p + 1);
  };

  const prev = (e) => {
    e.preventDefault();

    if (pageNo <= 1) {
      return;
    }
    setPageNo((p) => p - 1);
  };

  const changeInput = (e) => {
    e.preventDefault();
    setInvalidGiftCard(false);
    setCode(e.target.value?.toUpperCase());
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }
    window.scrollTo(0, 0);
    // getFilterYear()
    getGiftCardTransactions();
  }, []);

  // console.log(history);

  return (
    <Layout sideBarActive={8}>
      {/* <div>
                <div className='box_with_shadow_padding load_animation' style={{
                    width: "60%", height: "100%", margin: "0 auto", backgroundColor: "#EDFAFF",
                    padding: "30px", borderRadius: "15px", marginBottom: "50px"
                }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <Form >
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label style={{ fontWeight: "600" }}>Illuminate Gift Card</Form.Label>
                                    <Form.Control type="text" placeholder="Enter the code here" />
                                </Form.Group>
                                <Button style={{ width: "100%", backgroundColor: "#0f4c62", border: "1px solid #0f4c62", fontWeight: "600" }} variant="primary">
                                    Redeem Now
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="box_with_shadow_padding mb-5">
        <div
          className="searchBar"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="left">
            <span style={{ fontSize: "1rem", fontWeight: "600" }}>
              List of redeemed giftcards
            </span>
            {/* <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Search by keyword" />
                                <Button>Search</Button>
                            </Form.Group>
                        </Form> */}
          </div>
          {/* <div>
                        <Button onClick={() => { setRedeem(true) }} style={{ width: "100%", padding: "10px 35px", backgroundColor: "#0f4c62", border: "1px solid #0f4c62", fontWeight: "600" }} >
                            Redeem<span style={{ marginLeft: "5px" }}><FontAwesomeIcon icon={faGift} /></span>
                        </Button>
                    </div> */}
          <div className="right">
            <Button
              onClick={() => {
                setRedeem(true);
              }}
              style={{
                width: "100%",
                padding: "10px 20px",
                backgroundColor: "#0f4c62",
                border: "1px solid #0f4c62",
                fontWeight: "600",
                boxShadow: "0px 0px 8px grey",
              }}
            >
              Redeem
              <span style={{ marginLeft: "10px" }}>
                <FontAwesomeIcon icon={faGift} />
              </span>
            </Button>

            {/* <select onChange={handleYearFilter} className="form-select" aria-label="Default select example">
                            <option selected value="all">All</option>
                            {filter?.length ?
                                filter.map(el => {
                                    return (<option value={`${el}`}>{el}</option>)
                                }) : null}
                        </select> */}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="bodyPart load_animation">
            <div className="table ">
              {/* <div style={{ textAlign: "center", fontWeight: "600" }}>Gift Card Redemption History</div> */}
              <Table striped className="colorTable" responsive>
                <thead>
                  <tr>
                    <th className="blue_th3 TransactionHistoryDate">
                      {" "}
                      Date & Time
                    </th>
                    <th className="blue_th3">Gift Code</th>
                    <th className="blue_th3">Purchase Type</th>
                    <th className="blue_th3">Payment Mode</th>
                    <th className="blue_th3">No. of Searches</th>
                    <th className="blue_th3">Expiry</th>
                  </tr>
                </thead>
                <tbody>
                  {history?.length
                    ? history?.map((el, idx) => {
                        return (
                          idx >= 10 * (pageNo - 1) &&
                          idx < 10 * pageNo && (
                            <tr>
                              <td className="normal_text">
                                <span className="red_text">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>{" "}
                                {el.purchase_date} |{" "}
                                <span className="greenText">
                                  <FontAwesomeIcon
                                    style={{ marginRight: "3px" }}
                                    icon={faClock}
                                  />
                                </span>
                                {el?.purchase_time}
                              </td>
                              <td className="greenText bold_text">
                                {el.gift_code}
                              </td>
                              <td>{el?.purchase_type}</td>
                              <td>{el.payment_mode}</td>
                              <td>{commaInNumber(el.no_of_searches)}</td>
                              <td>Never</td>
                            </tr>
                          )
                        );
                      })
                    : null}
                </tbody>
              </Table>
              {history?.length === 0 && (
                <h3 style={{ textAlign: "center", fontWeight: "600" }}>
                  No Data Found!
                </h3>
              )}
            </div>
          </div>
        )}
        {
          // history?.length > 0 &&
          // <div className='bottomPart'>
          //     <div className='right'>
          //         <nav aria-label="...">
          //             <ul class="pagination">
          //                 <li name="prev" onClick={prev} style={{ cursor: "pointer" }} class={pageNo <= 1 ? "page-item page-link disabled" : "page-item page-link"}>Previous
          //                     {/* <span class="page-link">Previous</span> */}
          //                 </li>
          //                 <li class="page-item "><a onClick={() => { setPageNo(pageNo <= 1 ? pageNo : pageNo - 1) }} class="page-link" href="#">{pageNo > 1 ? pageNo - 1 : null}</a></li>
          //                 <li class="page-item active" aria-current="page">
          //                     <span onClick={() => { setPageNo(pageNo) }} class="page-link">{pageNo}</span>
          //                 </li>
          //                 <li class="page-item"><a onClick={() => { setPageNo(pageNo > history.length / 10 ? pageNo : pageNo + 1) }} class="page-link" href="#">{pageNo + 1}</a></li>
          //                 <li name="next" onClick={next} style={{ cursor: "pointer" }} class="page-item page-link">Next
          //                     {/* <a class="page-link" href="#">Next</a> */}
          //                 </li>
          //             </ul>
          //         </nav>
          //     </div>
          // </div>
        }
      </div>

      <Modal
        centered
        show={redeem}
        onHide={closeModal}
        className="coupon_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>
            {giftCardDetails
              ? "Congratulations!!!"
              : "Redeem an Illuminate Gift Card"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          {giftCardDetails ? (
            <div
              style={{
                color: "green",
                fontWeight: "400",
                padding: "30px",
                textAlign: "center",
              }}
            >
              You have just received{" "}
              <span style={{ fontWeight: "600" }}>
                {giftCardDetails?.no_of_searches}
              </span>{" "}
              additional searches.
            </div>
          ) : (
            <div>
              <div
                className="box_with_shadow_padding load_animation"
                style={{
                  backgroundColor: "#EDFAFF",
                  padding: "30px",
                  borderRadius: "15px",
                }}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label
                          style={{
                            fontWeight: "600",
                            color: invalidGiftCard ? "red" : "black",
                          }}
                        >
                          {invalidGiftCard
                            ? "Invalid Gift Card!"
                            : "Illuminate Gift Card"}
                        </Form.Label>
                        <Form.Control
                          onChange={changeInput}
                          value={code}
                          type="text"
                          placeholder="Enter the code here"
                        />
                      </Form.Group>
                      <Button
                        disabled={loadGift}
                        onClick={redeemGift}
                        style={{
                          width: "100%",
                          backgroundColor: "#0f4c62",
                          border: "1px solid #0f4c62",
                          fontWeight: "600",
                          boxShadow: "0px 0px 8px grey",
                        }}
                        variant="primary"
                      >
                        {loadGift ? "Redeeming Your Gift Card.." : "Redeem Now"}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
