import React, { useEffect } from "react";
import { useState } from "react";

export default function TodayDate() {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");

  let date = new Date().toString();
  let currDate = date.slice(8, 10);
//   console.log(date?.slice(4, 7));

  function handleDate(date) {
    // let date = date.slice(8, 10);
    let monthh = date?.slice(4, 7);
    let day = date?.slice(0, 3);
    // console.log(date);

    // manipulating days
    if (day == "Mon") {
      setDay("Monday");
    }
    if (day == "Tue") {
      setDay("Tuesday");
    }
    if (day == "Wed") {
      setDay("Wednesday");
    }
    if (day == "Thu") {
      setDay("Thursday");
    }
    if (day == "Fri") {
      setDay("Friday");
    }
    if (day == "Sat") {
      setDay("Saturday");
    }
    if (day == "Sun") {
      setDay("Sunday");
    }

    // manipulating months
    if (monthh == "Jan") {
      setMonth("January");
    }
    if (monthh == "Feb") {
      setMonth("February");
    }
    if (monthh == "Mar") {
      setMonth("March");
    }
    if (monthh == "Apr") {
      setMonth("April");
    }
    if (monthh == "May") {
      setMonth("May");
    }
    if (monthh == "Jun") {
      setMonth("June");
    }
    if (monthh == "Jul") {
      setMonth("July");
    }
    if (monthh == "Aug") {
      setMonth("August");
    }
    if (monthh == "Sep") {
      setMonth("September");
    }
    if (monthh == "Oct") {
      setMonth("October");
    }
    if (monthh == "Nov") {
      setMonth("November");
    }
    if (monthh == "Dec") {
      setMonth("December");
    }
  }

  useEffect(() => {
    handleDate(date);
  }, []);

  return (
    <>
      <div className="welcomeTextTop">
        {currDate}
        <sup>
          {currDate > 10 && currDate < 20
            ? "th"
            : currDate % 10 == 1
            ? "st"
            : currDate % 10 == 2
            ? "nd"
            : currDate % 10 == 3
            ? "rd"
            : "th"}
        </sup>{" "}
        {month}, {day}, {date.slice(10, 15)}
      </div>
    </>
  );
}
