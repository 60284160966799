import React from "react";
import readXlsxFile from 'read-excel-file'
// import Modal from 'react-bootstrap/esm/Modal'
import { Button, Form, Modal } from "react-bootstrap";
import { useEffect } from "react";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { useState } from "react";
import axios from "axios";
import { api } from '../../assets/API/api';
import { RotatingLines } from "react-loader-spinner";




export default function SendGiftCardInvite({ emails, send }) {

    const [allEmails, setAllEmails] = emails;
    const [sendInvite, setSendInvite] = send;
    const temp = getSessionStorage();
    const [loading, setLoading] = useState(false);
    const [giftCards, setGiftCards] = useState([]);
    const [selectedGiftCard, setSelectedGiftCard] = useState(null)


    const closeModal = () => {
        setSendInvite(false);
        setAllEmails([]);
    }


    const getAllGiftCards = async () => {
        setLoading(true)
        await axios.get(`${api}/get-all-giftcards/?tokenMail=${temp?.email}&admin=true`)
            .then(res => {
                // console.log(res.data);
                setGiftCards(res.data);
                if (res?.data?.length) { setSelectedGiftCard(res.data[0]) }
            })
            .catch(err => { console.log(err) })
            .finally(() => { setLoading(false); })
    }


    const readExcelFile = e => {
        e.preventDefault();
        let emails = [];
        let files = e.target.files[0];
        // console.log(files);
        readXlsxFile(files).then((rows) => {
            // console.log(rows);
            // `rows` is an array of rows
            // each row being an array of cells.
            rows.map((row, idx) => { idx !== 0 && row[0]?.includes('@') && row[0]?.includes('.') && emails.push(row[0]) })
            // console.log(emails);
            setAllEmails(emails);
        })
            .catch(err => { console.log(err); alert('Falied to read file!') })

    }


    const invite = async e => {
        e.preventDefault();

        if (!temp?.user_id || temp?.userType !== 'Company Head') { return; }
        if (!allEmails?.length) { return alert('Please select an excel file.') }
        try {
            setLoading(true);
            let { data } = await axios.post(`${api}/send-giftcard-invite/?tokenMail=${temp?.email}`,
                {
                    allEmails: allEmails,
                    giftCard: selectedGiftCard
                });
            setLoading(false)
            alert(data);
            closeModal();
        } catch (error) {
            setLoading(false)
            alert('Something went wrong!')
        }
    }


    useEffect(() => {
        if (!temp?.user_id || temp?.userType !== 'Company Head') { return; }
        getAllGiftCards();
    }, [])


    // console.log(selectedGiftCard);
    return (
        <>
            <Modal centered show={sendInvite} onHide={closeModal} className='coupon_modal'>
                <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
                    <Modal.Title style={{ color: "red" }}>Send Gift Card Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <div style={{ textAlign: 'center', padding: "0 5px", fontSize: "12px", color: "green" }}>
                        Label cell <span style={{ fontWeight: "600" }}>A1</span> as <span style={{ fontWeight: "600" }}>email</span> and add emails under it.<br></br>Do not skip any rows when adding all of the users.
                    </div>
                    <div className="planCreate" style={{ paddingTop: "0px" }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <Form.Group>
                                    <Form.Label>Import Emails From an Excel File</Form.Label>
                                    <Form.Control onChange={readExcelFile} name="" type="file" placeholder="" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-12">
                                <Form.Group className="mb-3" controlId="formEmailID">
                                    <Form.Label>Select Gift Card</Form.Label>
                                    {/* <Form.Control onChange={handleChange} value={userInfo.highestQualification} name="highestQualification" type="text" placeholder="" /> */}
                                    <select name="gift-card" value={selectedGiftCard?.code} className="form-select" aria-label="Default select example">
                                        {giftCards?.length > 0 &&
                                            giftCards.map((el, idx) => {
                                                return (<option onClick={() => { setSelectedGiftCard(giftCards[idx]) }} value={`${el?.code}`}>{el?.code}</option>)
                                            })}
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="col-lg-12">
                                <Button disabled={loading} onClick={invite} style={{ width: "100%" }}>
                                    {loading ? "Sending Invite.." : "Invite"}
                                </Button>
                            </div>
                        </div>
                        {loading && <div style={{
                            zIndex: "5", position: "fixed", top: '50%', left: '50%',
                            transform: "translate(-50%,-50%)", display: 'flex', justifyContent: "center",
                            backgroundColor: '#0f4c62', borderRadius: '5px'
                        }}>
                            <RotatingLines
                                strokeColor="azure"
                                strokeWidth="3"
                                animationDuration="0.75"
                                width="30"
                                visible={true}
                            />
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}