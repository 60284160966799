// import { useEffect } from "react";
// import {
//     PayPalScriptProvider,
//     PayPalButtons,
//     usePayPalScriptReducer
// } from "@paypal/react-paypal-js";
// import { useContext } from "react";
// import { myContext } from "../../App";
import axios from 'axios';
import { api } from '../../assets/API/api'
import { useState } from "react";
// import { useHistory } from "react-router-dom";
// import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";
import '../Subscription/style.css';
import { RotatingLines } from "react-loader-spinner";
// import Layout from "../Layout/Layout";
// import { Button } from "react-bootstrap";
import getSessionStorage from "../../customHooks/getSessionStorage";
import paypal_logo from '../../assets/images/PayPal_logo.png'

// This values are the props in the UI
// const amount = "5";
// const currency = "USD";
let style = { "layout": "vertical" };

style = {
    "layout": 'horizontal',
    "tagline": 'false'
}

// Custom component to wrap the PayPalButtons and handle currency changes
// export default function ButtonWrapper({ currency, showSpinner, amount, planDetails, state, showPayPal, agree }) {
//     const st = useContext(myContext);
//     const [userInfo, setUserInfo] = st.userInfo;
//     const [paymentStatus, setPaymentStatus] = state;
//     const [authenticate, setAuthenticate] = st.authentication;
//     const [show, setShow] = useState(false);
//     // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
//     // This is the main reason to wrap the PayPalButtons in a new component
//     const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

//     // console.log(planDetails.pay_by_search_id ? "Pay By Search" : planDetails.annual_plan_id ? "Annual Plan" : planDetails.monthly_plan_id ? "Monthly Plan" : null)

//     // console.log(planDetails);

//     let hist = useHistory();

//     const expiry = (date, month, year, type) => {
//         if (type == 'monthly') {
//             if (month == 12) {
//                 month = 1;
//                 year = year + 1;
//                 date--;
//                 return `${year}-${month}-${date}`;
//             }
//             month++;
//             date--;
//             return `${year}-${month}-${date}`;
//         }
//         if (type == 'annual') {
//             year++;
//             date--;
//             return `${year}-${month}-${date}`;
//         }
//         return 'NA';
//     }


//     var temp = JSON.parse(localStorage.getItem("user-data"));

//     const updateTransaction = async (orderId, payerId, actions) => {
//         if (!authenticate) {
//             alert('You are logged out ! Please login again..')
//             hist.push('/DashboardOldUser');
//             return;
//         }
//         let date = new Date();
//         let month = date.getMonth() + 1;
//         let year = date.getFullYear();
//         let hours = date.getHours();
//         let minutes = date.getMinutes();
//         date = date.getDate();
//         console.log(orderId, payerId);
//         await axios.post(`${api}/updateTransaction`, {
//             userId: userInfo.id || temp?.user_id,
//             orderId: orderId,
//             payerId: payerId,
//             purchaseType: planDetails.payBySearch_id ? "Pay By Search" : planDetails.annual_plan_id ? "Annual Plan" : planDetails.monthly_plan_id ? "Monthly Plan" : null,
//             duration: planDetails.payBySearch_id ? "NA" : planDetails.annual_plan_id ? "Annual" : planDetails.monthly_plan_id ? "Monthly" : null,
//             purchaseDate: `${year}-${month}-${date}`,
//             purchaseTime: minutes < 10 ? `${hours}:0${minutes}` : `${hours}:${minutes}`,
//             expiryDate: expiry(date, month, year, planDetails.annual_plan_id ? "annual" : planDetails.monthly_plan_id ? "monthly" : "Pay By Search"),
//             ...planDetails,
//             no_of_searches: parseInt(planDetails?.no_of_searches) + (parseInt(planDetails?.discount) || 0)
//         })
//             .then(res => {
//                 console.log(res.data);
//                 setPaymentStatus('success');
//             })
//             .catch(err => {
//                 console.error(err);
//                 setPaymentStatus('failed')
//             })
//     }


//     useEffect(() => {
//         dispatch({
//             type: "resetOptions",
//             value: {
//                 ...options,
//                 currency: currency,
//             },
//         });
//     }, [currency, showSpinner]);

//     useEffect(() => {
//         if (amount) { setShow(true) }
//     }, [amount])

//     // console.log('inside paypal', showSpinner, isPending)

//     return (<>
//         {/* {(showSpinner && isPending) && <div className="spinner" />} */}
//         {/* {showSpinner && isPending && <div style={{ display: 'flex', justifyContent: 'center' }}><RotatingLines
//             strokeColor="white"
//             strokeWidth="3"
//             animationDuration="0.75"
//             width="30"
//             visible={true} />
//         </div>} */}
//         {/* <div className="paypal-stripe-container" style={{ display: showPayPal && agree ? "block" : "none" }}> */}
//         {show && 
//         <PayPalButtons
//             style={style}
//             disabled={false}
//             forceReRender={[amount, currency, style]}
//             fundingSource={undefined}
//             createOrder={(data, actions) => {
//                 return actions.order
//                     .create({
//                         purchase_units: [
//                             {
//                                 amount: {
//                                     currency_code: currency,
//                                     value: amount,
//                                 },
//                             },
//                         ],
//                         application_context: {
//                             shipping_preference: "NO_SHIPPING",
//                         },
//                     })
//                     .then((orderId) => {
//                         // Your code here after create the order
//                         console.log(orderId);
//                         return orderId;
//                     });
//             }}
//             onApprove={function (data, actions) {
//                 return actions.order.capture().then(function () {
//                     // Your code here after capture the order
//                     console.log("payment success",);
//                     updateTransaction(data);
//                     // alert('Thank You for the Purchase !');
//                 });
//             }}
//             onError={function (err) {
//                 console.log(err);
//                 setPaymentStatus('failed')
//             }}
//             onCancel={(err) => {
//                 console.log(err)
//                 setPaymentStatus('failed')
//                 // let date = new Date();
//                 // let month = date.getMonth()+1;
//                 // let year = date.getFullYear();
//                 // date = date.getDate();
//                 // console.log(date, month, year);
//             }}
//         />}
//         {/* </div> */}
//     </>
//     )
// }

export default function PayPal({ plan, amount }) {
    const [loading, setLoading] = useState(false)
    const temp = getSessionStorage();

    const pay = (e) => {
        e.preventDefault();
        if (!temp?.user_id || !plan || !amount) { return }

        setLoading(true)

        if (plan?.payBySearch_id) {
            axios.post(`${api}/pay-pal-payment?tokenMail=${temp?.email}`, {
                user_id: temp?.user_id,
                email: temp?.email,
                name: `${temp?.firstName} ${temp?.lastName}`,
                amount: amount,
                currency_code: plan?.currency_code,
                plan: plan
            })
                .then(res => {
                    // console.log(res.data);
                    window.location.href = res.data;
                })
                .catch(e => console.log(e))
                .finally(() => { setLoading(false) })
        }
        else {
            axios.post(`${api}/pay-pal-subscription?tokenMail=${temp?.email}`, {
                user_id: temp?.user_id,
                email: temp?.email,
                name: `${temp?.firstName} ${temp?.lastName}`,
                amount: amount,
                currency_code: plan?.currency_code,
                plan: plan
            })
                .then(res => {
                    // console.log(res.data);
                    window.location.href = res.data;
                })
                .catch(e => console.log(e))
                .finally(() => { setLoading(false) })
        }
    }
    return (
        // <Layout>
        <div style={{ display: "flex", justifyContent: "center" }} className="">
            <button className={loading ? "loading_payment" : ""} disabled={loading} style={{ height: "50px", width: "300px" }} variant="primary"
                onClick={pay}>
                {loading
                    ?
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <RotatingLines
                            strokeColor="black"
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="34"
                            visible={true} />
                    </span>
                    :
                    // <span style={{ fontSize: "20px", fontWeight: "600", fontStyle: "italic" }}>PayPal</span>
                    <img style={{ height: '33px' }} src={paypal_logo} alt='PayPal' />
                }
            </button>
        </div>
        // </Layout>
    )
}