import React from "react";
import { useState } from "react";
// import { Button } from "react-bootstrap";
import './style.css';
import '../Subscription/style.css'
// import logo_inner from "../../assets/images/logo_inner.png"
// import ButtonWrapper from "../PayPal/PayPal";
// import {
//     PayPalScriptProvider,
//     PayPalButtons,
//     usePayPalScriptReducer
// } from "@paypal/react-paypal-js";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Layout from "../Layout/Layout";
import Stripe from "../stripe/stripe";
import PayPal from "../PayPal/PayPal";
import { useRef } from "react";
// import { useRef } from "react";



// let style = { "layout": "vertical" };

// let style = {
//     "layout": 'horizontal',
//     "tagline": 'false'
// }

// let style1 = {
//     shape: 'rect',
//     color: 'gold',
//     layout: 'horizontal',
//     label: 'subscribe',
//     tagline: 'false'
// }



export default function PaymentGateway() {

    // const [show, setShow] = useState(false);
    const [plan, setPlan] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState('')
    // let ref = useRef();
    let hist = useHistory();
    // console.log(hist.location.state);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setPlan(hist.location.state);
    }, [hist])

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, [])

    let ref = useRef(null);


    console.log('plan: ', plan);


    return (
        <div ref={ref}>
            <Layout>
                <div className="load_animation" style={{ height: "450px", display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <h1 style={{ textAlign: "center", fontWeight: "500", marginTop: "25px", marginBottom: "55px" }}>Buy using any one of the below payment gateways.</h1>
                        <div className="payment_container_main">
                            <div className="payment_button">
                                <PayPal plan={plan} amount={plan?.rate} />
                            </div>
                            <div className="payment_button">
                                <Stripe plan={plan} amount={plan?.rate} />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}