import { React, useState, useEffect, useContext } from 'react'
import Layout from '../Layout/Layout'
//import { Button } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
// import {
//     PayPalScriptProvider,
//     PayPalButtons,
//     usePayPalScriptReducer
// } from "@paypal/react-paypal-js";
// import ButtonWrapper from "../PayPal/PayPal";
// import AmazonPayComponent from '../Payments/amazonPay';

// import payPal from "../../assets/images/PayPal_logo.png"
// import saveNotification from "../../assets/images/saveNotification.svg"
// import notiNotification from "../../assets/images/notiNotification.svg"
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../assets/API/api';
import { myContext } from '../../App';
// import Stripe from '../stripe/stripe';
import '../Subscription/style.css';
import getSessionStorage from '../../customHooks/getSessionStorage';
// import temp from '../../customHooks/getSessionStorage';




let style = {
    "layout": 'horizontal',
    "tagline": 'false'
}

style = {
    shape: 'rect',
    color: 'gold',
    layout: 'horizontal',
    label: 'subscribe',
    tagline: 'false'
}







const PaymentOptions = () => {

    const st = useContext(myContext);
    const [userInfo, setUserInfo] = st.userInfo;
    const [authenticate, setAuthenticate] = st.authentication;
    const [activePlans, setActivePlans] = st.activePlans;
    const [show, setShow] = useState(false);
    const [showPayPal, setShowPayPal] = useState(false);
    const [plan, setPlan] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [invalidCoupon, setInalidCoupon] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [finalPrice, setfinalPrice] = useState();
    const [morePlans, setMorePlans] = useState(false);
    const [addons, setAddons] = useState([]);
    const [addonPrice, setAddonPrice] = useState(0);
    const [couponDetails, setCouponDetails] = useState([]);
    const [myAddon, setMyAddon] = useState();
    const [agree, setAgree] = useState(false);
    const [apply, setApply] = useState(true);
    const [autoPay, setAutoPay] = useState(false);
    const [trial, setTrial] = st.trial;
    const [finalNoOfSearches, setFinalNoOfSearches] = useState(null);
    const [greet, setGreet] = useState(false);
    const [usFinalRate, setusFinalRate] = useState();
    const temp = getSessionStorage();


    // let temp = JSON.parse(localStorage.getItem("user-data"));


    const handleClose = () => {
        setInalidCoupon(false);
        setCouponCode('');
        setShow(false);
    }

    const handleShow = () => setShow(true);

    let hist = useHistory();

    let date = new Date().toJSON().slice(0, 10);


    const expiry = (type) => {
        let today = new Date();


        if (type == 'monthly') {
            let nextDate = new Date(new Date().setMonth(today.getMonth() + 1));

            return nextDate?.toJSON()?.slice(0, 10);
        }
        if (type == 'annual') {
            let nextDate = new Date(new Date().setMonth(today.getMonth() + 12));

            return nextDate?.toJSON()?.slice(0, 10);
        }
        return 'NA';
    }



    const noOfSearches = (x) => {

        // console.log(x);
        let sal = x.toString();
        let newSal = '';
        let c = 0;
        for (let i = sal.length - 1; i >= 0; i--) {
            c++;
            if ((c) % 3 === 0 && i !== 0) {
                newSal += sal[i];
                newSal += ',';
            }
            else
                newSal += sal[i];
        }
        let tempSal = newSal;
        newSal = '';
        for (let i = tempSal.length - 1; i >= 0; i--) {
            newSal += tempSal[i];
        }
        return newSal;
    }

    useEffect(() => {

        window.scrollTo(0, 0);

        if (!temp?.user_id) {
            alert('You are logged out ! Please login again..')
            hist.push('/DashboardOldUser');
            return;
        }

        // if ((userInfo?.oldUser || temp?.user_id) && (userInfo?.id || temp?.user_id)) {
        //     axios.post(`${api}/getUserInfo?tokenMail=${temp?.email}`, { id: userInfo?.id || temp?.user_id })
        //         .then(res => {
        //             let data = res.data[0];
        //             setUserInfo({
        //                 ...userInfo,
        //                 firstName: data.firstName,
        //                 lastName: data.lastName,
        //                 mobile: data.mobile,
        //                 companyName: data.companyName,
        //                 yourRole: data.role,
        //                 companyWebsite: data.companyWebsite,
        //                 businessCategories: data.businessCategories,
        //                 address: data.address,
        //                 city: data.city,
        //                 state: data.state,
        //                 zipCode: data.zipCode,
        //                 country: data.country,
        //                 userType: data.userType
        //             })
        //         })
        //         .catch(err => console.log(err))
        // }


        setPlan(hist.location.state);
        setfinalPrice(hist.location.state[0]?.rate);
        setusFinalRate(hist.location.state[0]?.rate);
        // axios.get(`${api}/subscriptionPlans`)
        //     .then(res => {
        //         // console.log(res.data);
        //         setAddons(res.data.filter(e => e.payBySearch_id));
        //     })
        //     .catch(err => console.log(err))

    }, [])


    const handleChange = (e) => {
        e.preventDefault();
        setInalidCoupon(false);
        setCouponCode(e.target.value.toUpperCase());
    }

    const applyCoupon = (e) => {
        e.preventDefault();
        if (!couponCode) {
            return;
        }
        setInalidCoupon(false);

        axios.post(`${api}/getCoupons?tokenMail=${temp?.email}`, { coupon: couponCode, user_id: temp?.user_id })
            .then(res => {
                // console.log(res.data.data);
                if (!res.data.validCoupon) {
                    setInalidCoupon(true);
                }
                else {
                    let data = res.data?.data;
                    setCouponDetails(data);
                    // console.log(data);
                    let discount = parseInt(data[0]?.discount);
                    // console.log(discount);
                    if (plan[0]?.payBySearch_id) {
                        let iRate = parseFloat(plan[0]?.rate);
                        let fRate = iRate - ((iRate / 100) * discount);
                        let usInRate = parseFloat(plan[0]?.us_rate);
                        let usFiRate = iRate - ((usInRate / 100) * discount);
                        setfinalPrice(parseFloat(fRate.toFixed(2)));
                        setusFinalRate(usFiRate);
                        setGreet({ mode: 'monetary', benefit: fRate });
                    }
                    else {
                        let iNoOfSearches = parseInt(plan[0]?.no_of_searches) + parseInt(plan[0]?.discount);
                        let fRate = iNoOfSearches + Math.ceil((iNoOfSearches / 100) * discount);
                        setFinalNoOfSearches({ extra: fRate - iNoOfSearches, final: fRate });
                        setGreet({ mode: 'extra-searches', benefit: fRate });
                    }
                    handleClose();
                    setApply(false);
                }
                // setCoupons(res.data);
            })
            .catch(err => {
                console.log(err);
                setInalidCoupon(true);
            })
    }

    const removeCoupon = e => {
        e.preventDefault();
        setfinalPrice(plan[0]?.rate);
        setFinalNoOfSearches(null)
        setCouponDetails([]);
        setApply(true);
    }

    // const showAddons = e => {
    //     e.preventDefault();
    //     setMorePlans(true);
    // }

    const addAddon = (e, data) => {
        e.preventDefault();
        // console.log(data);
        setAddonPrice(data.rate);
        setMyAddon(data);
        // let addonPrice = data.rate;
        // let newPrice = parseFloat(addonPrice) + parseFloat(finalPrice);
        // console.log(newPrice.toFixed(2))
        // setFinalPrice2(newPrice.toFixed(2));
        setMorePlans(false);
    }


    // console.log('final price : ', finalPrice, couponDetails);
    // console.log('no of searches after coupon', finalNoOfSearches)

    return (
        <Layout>
            <div className='row paymentPage load_animation'>
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                    <div className="purchaseDetails">
                        <h4>Purchase Details</h4>
                        <div className="contentWrapper">
                            <div className="contentItem">
                                <label>Purchase Type</label>
                                <span>{plan[0]?.annual_plan_id ? "Annual Plan" : plan[0]?.monthly_plan_id ? "Monthly Plan" : plan[0]?.payBySearch_id ? "Subscription Plan" : null}</span>
                            </div>
                            <div className="contentItem">
                                <label>Plan Name</label>
                                <span className='highLight'>{plan[0]?.payBySearch_id ? "Pay By Search" : plan[0]?.plan_name}</span>
                            </div>
                            <div className="contentItem">
                                <label>Duration</label>
                                <span>{plan[0]?.annual_plan_id ? "Annual" : plan[0]?.monthly_plan_id ? "Monthly" : plan[0]?.payBySearch_id ? "NA" : null}</span>
                            </div>
                            <div className="contentItem">
                                <div>No. of Searches</div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <span>{noOfSearches(parseInt(plan[0]?.no_of_searches) + (parseInt(plan[0]?.discount || 0)))}</span>
                                    {finalNoOfSearches &&
                                        <span style={{ color: "green", marginLeft: '5px' }}>{' + '}{finalNoOfSearches?.extra}{' = '}{finalNoOfSearches?.final}</span>}
                                </div>
                            </div>
                            <div className="contentItem">
                                <label>Date of Purchase</label>
                                <span>{date}</span>
                            </div>
                            <div className="contentItem">
                                <label>Date of Plan Expires</label>
                                <span>{expiry(plan[0]?.annual_plan_id ? "annual" : plan[0]?.monthly_plan_id ? "monthly" : "Pay By Search")}</span>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    {/* <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ marginBottom: "1rem", display: "grid", gridTemplateColumns: "50% 50%" }}>
                            <div
                                onClick={showAddons}
                                style={{ cursor: "pointer", color: "blue", fontWeight: "600" }}>Need more searches?</div>
                            {myAddon ? <div onClick={() => { setMyAddon(); setAddonPrice(0) }} style={{ cursor: "pointer", fontWeight: "300", color: "red", textAlign: "right" }}>Edit Searches</div> : null}
                        </div>
                        {myAddon ? <div style={{ display: "grid", gridTemplateColumns: "50% 50%", alignContent: "space-between" }}>
                            <div style={{ fontWeight: "600", color: "orangered" }}>More Searches Added: </div>
                            <div style={{ textAlign: "right", color: "red", fontWeight: "600" }}>{myAddon?.no_of_searches} searches for ${myAddon.rate}</div>
                        </div> : null}
                    </div> */}
                    {/* <hr></hr> */}
                    <div className="price[0]?">
                        <h4>Price Details</h4>
                        <div className="contentWrapper">
                            <div className="contentItem">
                                <label>Subtotal</label>
                                {/* <span>{plan[0]?.rate} USD$</span> */}
                                <span>{plan[0]?.symbol}{" "}{plan[0]?.rate?.toFixed(2)}</span>
                            </div>
                            <div className="contentItem">
                                <div style={{ display: "flex" }}><label>Coupon Discount </label>{!apply && <span style={{ cursor: "pointer", color: "red", marginLeft: "10px", fontSize: "10px", fontWeight: "400" }} onClick={removeCoupon}>(Remove Coupon)</span>}</div>
                                <span className='discount'>
                                    {
                                        finalNoOfSearches ?
                                            <>{finalNoOfSearches?.extra}{' searches added'}</>
                                            :
                                            <>(-{couponDetails[0]?.discount || 0} %) {plan[0]?.symbol}{" "}{(finalPrice - plan[0]?.rate)}</>
                                    }
                                </span>
                            </div>
                            <div className="contentItem">
                                {/* <label>Coupon Discount</label> */}
                                <label></label>
                                <span>
                                    {apply && <span style={{ cursor: "pointer", fontWeight: "400" }} className='applyCoupon' onClick={handleShow}>Apply Coupon</span>}
                                    {/* <span style={{ cursor: "pointer" }} onClick={removeCoupon}>Remove Coupon</span> */}
                                </span>


                            </div>
                            <div className="contentItem totalAmount">
                                <label>Total Amount</label>
                                <div>
                                    {/* <span>{(parseFloat(finalPrice) + parseFloat(addonPrice))?.toFixed(2)} USD$</span> */}
                                    <span>{plan[0]?.symbol}{" "}{finalPrice?.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    {paymentStatus == 'success' ? <div style={{ color: "green" }}>Thank You for making the purchase!</div> :
                        <>
                            {paymentStatus == 'failed' && <div style={{ color: "red" }}>Oops! <br></br> Payment failed! Please go back and complete the payment process again.<br></br> Thank You!</div>}
                            <div className="placeOrder">
                                <div className="accordion" id="placeOrder">
                                    <div className="accordion-item1">
                                        {<>
                                            <input onChange={(e) => { e.target.checked ? setAgree(true) : setAgree(false) }} style={{ marginRight: "15px", marginBottom: "15px" }} type="checkbox" /><label style={{ color: agree ? "green" : "brown", fontWeight: "400" }}>I agree to the <a href="https://upskilltalent.com/terms-of-use/" target={"_blank"}>terms</a> and <a href="https://upskilltalent.com/subscribe-as-a-service/" target={"_blank"}>conditions</a> set forth by Upskill Talent.</label>
                                            {!plan[0]?.payBySearch_id && <div style={{ display: "flex" }}>
                                                <input onChange={e => { setAutoPay(p => !p) }} type={"checkbox"} style={{ marginRight: "15px", marginBottom: "15px" }} /><label style={{ color: autoPay ? "green" : "brown", fontWeight: "400" }}>This is a recurring payment. Agree to let PayPal deduct money from your bank account on monthly/yearly basis based on your subscription plan.</label>
                                            </div>}
                                            <button onClick={(e) => {
                                                e.preventDefault();

                                                if (!temp?.user_id) {
                                                    alert('You are logged out ! Please login again..')
                                                    hist.push('/DashboardOldUser');
                                                    return;
                                                }
                                                if (!agree) {
                                                    alert("Please agree to the terms and conditions!");
                                                }
                                                else if (!autoPay) {
                                                    if (plan[0]?.payBySearch_id) {
                                                        setShowPayPal(true);
                                                        hist.push("PaymentGateway", { ...plan[0], rate: finalPrice, coupon: couponDetails[0]?.header || '', us_rate: usFinalRate });
                                                        return;
                                                    }
                                                    alert("Please agree for auto payment feature!");
                                                }
                                                else {
                                                    setShowPayPal(true)
                                                    hist.push("PaymentGateway", { ...plan[0], coupon: couponDetails[0]?.header || '', no_of_searches_with_coupon: finalNoOfSearches?.final || 0 });
                                                }

                                            }}
                                                style={{ marginTop: "20px", marginBottom: "30px" }}
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className='placeOrder'>
                                                <span>Place Order</span>
                                            </button></>}

                                    </div>
                                </div>
                            </div>
                        </>}
                </div>


                <Modal show={greet} onHide={() => { setGreet(false) }} size="" className='coupon_modal'>
                    <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
                        <Modal.Title>Congratulations 🎉</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {greet?.mode === "monetary" ?
                            <div style={{ fontWeight: "500" }}>You received a <span style={{ color: 'green', fontWeight: "600" }}>{couponDetails[0]?.discount}%</span> discount for your Pay By Search purchase.</div>
                            :
                            <div style={{ fontWeight: '500' }}>You have now received an extra <span style={{ color: "green", fontWeight: "600" }}>{finalNoOfSearches?.extra}</span> searches on your {plan[0]?.plan_name} plan.</div>}
                    </Modal.Body>
                </Modal>


                <Modal centered show={show} onHide={handleClose} size="md" className='coupon_modal'>
                    <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
                        <Modal.Title>Apply Coupon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {invalidCoupon && <h3 style={{ color: "red" }}>Invalid Coupon!</h3>}
                        <span className="applyCouponCode">
                            <input
                                onChange={handleChange}
                                value={couponCode} type="text" placeholder='Apply Coupon' />
                            <Button onClick={applyCoupon}>Apply Coupon</Button>
                        </span>
                    </Modal.Body>
                </Modal>

                <Modal centered show={morePlans} onHide={() => { setMorePlans(false) }} size="md" className='coupon_modal addon_modal'>
                    <Modal.Header style={{ backgroundColor: "#EDFAFF" }} onClick={() => { setMorePlans(false) }} closeButton>
                        <Modal.Title style={{ fontSize: "15px" }}>Would you like to buy additional searches, so you don’t run out?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ alignItems: "center" }} >
                            <div style={{ width: "70%", alignItems: "center", marginLeft: "auto", marginRight: "auto" }}>
                                <table style={{ textAlign: "center", width: "100%" }} id="main-table" className="colorTable table-striped">
                                    <thead className='blue_td'>
                                        <td>No of searches</td>
                                        <td>Price ($)</td>
                                        <td>Action</td>
                                    </thead>
                                    <tbody>
                                        {addons?.map((el, idx) => {
                                            return (
                                                <tr>
                                                    <td>{el.no_of_searches}</td>
                                                    <td>{el.rate}</td>
                                                    <td><Button onClick={(e) => { addAddon(e, el) }}>Add</Button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        </Layout >

    )
}

export default PaymentOptions





