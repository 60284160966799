import React from "react";
import circle_icon from "../../assets/images/circle.png";

export default function NoDataFound({ height, text, margin }) {
  return (
    <div
      style={{
        height: height || "200px",
        margin: margin ? `${margin}px 0px` : "0",
      }}
      className="dotedBox load_animation"
    >
      <p>
        <img src={circle_icon} className="" loading="lazy" alt="" />
      </p>
      <p>{text || "No data found"}!</p>
    </div>
  );
}
