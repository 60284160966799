import React from "react";
// import { Button } from 'react-bootstrap'
import Table from "react-bootstrap/Table";

// import TransactionHistory_icon from "../../assets/images/TransactionHistory_icon.png"
// import calender_icon from "../../assets/images/calender_icon.png"
// import time_icon from "../../assets/images/time_icon.png"
import { useContext } from "react";
import { myContext } from "../../App";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";
// import { useEffect } from 'react'
// import { api } from '../../assets/API/api'
// import axios from 'axios'
// import { useState } from 'react'

const MembershipStatus = () => {
  const st = useContext(myContext);
  // const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [activePlans] = st.activePlans;

  // console.log("active palns = ", activePlans)

  return (
    <div>
      <div className="MyRecentTransactions_container">
        <div className="head">
          <span>Membership Status</span>
        </div>
        <div className="bottom">
          <Table className="MyActivityTable3" responsive>
            <thead>
              <tr>
                {/* <th>Plan Name</th> */}
                <th>Purchase Type</th>
                <th>Length of Plan</th>
                <th>Duration</th>
                <th>No. of Searches</th>
                <th>Expires on</th>
                <th className="text-end">
                  Amount({activePlans[0]?.cur_code || "USD"})
                </th>
              </tr>
            </thead>
            <tbody>
              {activePlans?.length
                ? activePlans?.map((el, idx) => {
                    return (
                      idx === 0 && (
                        <tr>
                          {/* <td className="greenText fw-bold">{el?.plan_name}</td> */}
                          <td className="greenText fw-bold">
                            {el.purchase_type}
                          </td>
                          <td>
                            <span>{el.plan_name}</span>
                          </td>
                          <td>{el.duration}</td>
                          <td>
                            {commaInNumber(
                              el.no_of_searches_with_coupon || el.no_of_searches
                            )}
                          </td>
                          <td>
                            <span className="date">{el.expiry_date}</span>
                          </td>
                          <td align="right">
                            {commaInNumber(parseFloat(el.amount)?.toFixed(2))}
                          </td>
                        </tr>
                      )
                    );
                  })
                : null}
            </tbody>
          </Table>
          {!activePlans?.length && (
            <h3 style={{ textAlign: "center" }}>
              No plans have been paid yet on membership status.
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembershipStatus;
