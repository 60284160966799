import axios from "axios";
// import React, { useEffect, useState } from "react";
import { api } from "../assets/API/api";
// import getSessionStorage from "./getSessionStorage";


// const useFetch = async (url, id) => {
//     const [data, setData] = useState([]);
//     let data ;
//     useEffect(async () => {
//         await axios.post(url, { id: id })
//             .then(res => { data = res.data })
//             .catch(e => { })
//     }, [url, id])

//     return data;
// }



const getUserDetails = async (id, tokenMail) => {

    if (!(id || tokenMail)) { return {} }

    let url = `${api}/getUserInfo?tokenMail=${tokenMail}`;
    try {
        let { data } = await axios.post(url, { id: id });
        if (data?.length)
            return data[0]
        else
            return {}
    } catch (e) {
        console.log('failed to get user details');
    }
    return {};
}


export default getUserDetails;