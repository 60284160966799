import React, { useEffect } from "react";
// import Form from 'react-bootstrap/Form'
import { useHistory } from "react-router-dom";

import BusinessOwner from "../../assets/images/BusinessOwner.png";
import WorkingProfessional from "../../assets/images/WorkingProfessional.png";
import login_logo_header from "../../assets/images/login_logo_header.png";

import FooterTwo from "../Footer/FooterTwo";
// import { Button } from 'react-bootstrap'
import { myContext } from "../../App";
import { useContext } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";

const Option = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [trial, setTrial] = st.trial;
  const [days, setDays] = st.days;
  const [myBalance, setMyBalance] = st.myBalance;
  let temp = getSessionStorage();
  let hist = useHistory();

  axios.defaults.headers["x-access-token"] = temp?.token;

  useEffect(() => {
    window.scrollY = 0;
  }, []);

  const handleClick1 = () => {
    setUserInfo({
      ...userInfo,
      userType: "Working Professional",
    });
    localStorage.setItem(
      "user-data",
      JSON.stringify({
        ...userInfo,
        user_id: userInfo.id,
        userType: "Working Professional",
        token: temp?.token,
      })
    );
    axios
      .post(`${api}/setUserType?tokenMail=${temp?.email}`, {
        id: userInfo?.id,
        userType: "Working Professional",
      })
      .then((res) => {
        console.log(res.data);
        setTrial("yes");
        setDays(0);
        setMyBalance({
          subscription_balance: 25,
          addon_balance: 0,
        });
        hist.push("/MyProfile");
      })
      .catch((err) => console.log(err));
  };

  const handleClick2 = () => {
    setUserInfo({
      ...userInfo,
      userType: "Business Owner",
    });
    localStorage.setItem(
      "user-data",
      JSON.stringify({
        ...userInfo,
        user_id: userInfo.id,
        userType: "Business Owner",
        token: temp?.token,
      })
    );

    axios
      .post(`${api}/setUserType?tokenMail=${temp?.email}`, {
        id: userInfo.id,
        userType: "Business Owner",
      })
      .then((res) => {
        console.log(res.data);
        setTrial("yes");
        setDays(0);
        setMyBalance({
          subscription_balance: 25,
          addon_balance: 0,
        });
        hist.push("/MyProfile");
      })
      .catch((err) => console.log(err));
  };
  // console.log(userInfo.userType);
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <span className="TopLogo">
            <img src={login_logo_header} className="" loading="lazy" alt="" />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col optionContent">
          <p>Are you a Working Professional or a Business Owner?</p>
          <ul>
            <li style={{ cursor: "pointer" }} onClick={handleClick1}>
              {/* <Link to='/MyProfile'> */}
              <img
                src={WorkingProfessional}
                loading="lazy"
                className=""
                alt=""
              />
              <p>Working Professional</p>
              {/* </Link> */}
            </li>
            <li style={{ cursor: "pointer" }} onClick={handleClick2}>
              {/* <Link to='/MyProfile'> */}
              <img src={BusinessOwner} loading="lazy" className="" alt="" />
              <p>Business Owner</p>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
      <div className="loginBottom">
        <FooterTwo />
      </div>
    </div>
  );
};

export default Option;
