import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./style.css";

import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import printer_icon from "../../assets/images/printer_icon.png";
import "../Subscription/style.css";

export default function Tableau() {
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const url =
    "https://public.tableau.com/views/Illuminatedashboard/AlternateTitleDashboard?:embed=yes&:comments=no&:toolbar=no&:refresh=yes";
  let temp = JSON.parse(localStorage.getItem("search-params"));

  let hist = useHistory();

  const handleClose = () => {
    setShow(false);
    hist.push("/SearchResults");
  };

  useEffect(() => {
    let temp = JSON.parse(localStorage.getItem("search-params"));
    if (!temp?.primaryProfession) {
      handleClose();
      return;
    }
    setTimeout(() => {
      setShow(true);
    }, 100);
    setSearchParams([
      temp?.primaryProfession || "",
      temp?.firstProfession || "",
      temp?.secondProfession || "",
      temp?.thirdProfession || "",
      temp?.fourthProfession || "",
      temp?.fifthProfession || "",
    ]);
  }, []);

  return (
    <>
      <Modal
        centered
        show={show && temp?.primaryProfession && searchParams.length > 0}
        onHide={handleClose}
        dialogClassName="modal-90w"
        className="coupon_modal load_animation_tableau"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title>Dashboard</Modal.Title>
          <div>
            <span style={{ color: "blue", fontSize: "15px" }}>
              Primary Profession:{" "}
            </span>
            {temp?.primaryProfession}
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: "0" }}>
          <div
            id="tableauId"
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div style={{ overflow: "auto" }}>
              {searchParams?.length > 0 && (
                <TableauComponent url={url} filterParameters={searchParams} />
              )}
              <div
                style={{
                  position: "relative",
                  height: "30px",
                  background: "white",
                  bottom: "30px",
                }}
              >
                <div className="printModal">
                  {" "}
                  <Button
                    onClick={() => {
                      window.print();
                    }}
                    className="print_button_orange"
                  >
                    <img src={printer_icon} loading="lazy" alt="" /> Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function TableauComponent({ url, filterParameters }) {
  if (!filterParameters?.length) return null;
  return (
    <tableau-viz id="tableauViz" src={url} hide-tabs={true} toolbar="hidden">
      <viz-filter field="Occupation" value={filterParameters}>
        {" "}
      </viz-filter>
    </tableau-viz>
  );
}
