import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import "./css/style.css";
// import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
// import { Link } from 'react-router-dom'
import Form from "react-bootstrap/Form";
// import { useContext } from "react";
// import { myContext } from "../../App";
import axios from "axios";
import { api } from "../../assets/API/api";
import { useRef } from "react";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from "react-bootstrap";
import Loader from "../Loader/loader";
import NoDataFound from "../NoDataFound/noDataFound";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

export default function SubscriptionControl() {
  // const st = useContext(myContext);
  // const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [annualPlans, setAnnualPlans] = useState([]);
  const [payBySearch, setPayBySearch] = useState([]);
  const [showAddon, setShowAddon] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [formData, setFormData] = useState({
    planName: "",
    noOfSearches: "",
    noOfUsers: "",
    rate: "",
    discount: "",
    planType: "",
    subscriptionId: "",
  });
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();

  const handleClose = () => {
    setShowPlans(false);
    setShowAddon(false);
    setFormData({
      planName: "",
      noOfSearches: "",
      noOfUsers: "",
      rate: "",
      discount: "",
      planType: "",
      subscriptionId: "",
    });
  };

  const getAllSubscriptions = async () => {
    setLoading(true);
    await axios
      .get(`${api}/subscriptionPlans/?tokenMail=${temp?.email}&admin=true`)
      .then((res) => {
        // console.log(res.data);
        setAnnualPlans(res.data.filter((el) => el.annual_plan_id));
        setMonthlyPlans(res.data.filter((el) => el.monthly_plan_id));
        setPayBySearch(res.data.filter((e) => e.payBySearch_id));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setFormData({
      planName: "",
      noOfSearches: "",
      noOfUsers: "",
      rate: "",
      discount: "",
      planType: "",
      subscriptionId: "",
    });

    if (!temp?.user_id) {
      return;
    }
    getAllSubscriptions();
  }, []);

  const showHide = (plan_id, planType) => {
    console.log(plan_id, planType);
    if (!temp?.user_id) {
      return;
    }

    setLoading(true);
    axios
      .post(`${api}/showHide?tokenMail=${temp?.email}`, {
        planId: plan_id,
        planType: planType,
      })
      .then((res) => {
        // console.log(res.data);
        getAllSubscriptions();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target);
    let name = e.target.name;
    let value = e.target.value;

    if (
      (name === "rate" ||
        name === "noOfUsers" ||
        name === "noOfSearches" ||
        name === "discount") &&
      isNaN(value)
    ) {
      return;
    }

    if (name == "annual") {
      setFormData({
        ...formData,
        [name]: true,
        monthly: false,
        addon: false,
      });
      return;
    }
    if (name == "monthly") {
      setFormData({
        ...formData,
        [name]: true,
        annual: false,
        addon: false,
      });
      return;
    }
    if (name == "addon") {
      setFormData({
        ...formData,
        [name]: true,
        monthly: false,
        annual: false,
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addPlan = (e) => {
    e.preventDefault();
    if (!temp?.user_id) {
      return;
    }

    if (formData.noOfSearches.includes(",")) {
      alert(`Remove ',' from no of searches.`);
      return;
    }

    axios
      .post(`${api}/addNewPlan`, formData)
      .then((res) => {
        // console.log(res.data);
        alert("New Plan Added Successfully!");
        setLoading(true);
        axios
          .get(`${api}/subscriptionPlans/?tokenMail=${temp?.email}&admin=true`)
          .then((res) => {
            // console.log(res.data);
            setAnnualPlans(res.data.filter((el) => el.annual_plan_id));
            setMonthlyPlans(res.data.filter((el) => el.monthly_plan_id));
            setPayBySearch(res.data.filter((e) => e.payBySearch_id));
            setShowPlans(false);
            setShowAddon(false);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
            handleClose();
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletePlan = async (plan_id, planType) => {
    if (!temp?.user_id) {
      return;
    }
    let ok = window.confirm("Are you sure to delete?");
    if (!ok) {
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${api}/delete-plan?tokenMail=${temp?.email}`, {
        planId: plan_id,
        planType: planType,
      });
      getAllSubscriptions();
    } catch (error) {}
  };

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [showPlans, showAddon]);

  let ref = useRef(null);

  // console.log(formData);

  return (
    <Layout sideBarActive={11}>
      <div>
        <div className="SubscriptionTab load_animation">
          <ul
            className="nav nav-tabs SubscriptionTabPlan"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                onClick={() => {
                  setShowPlans(false);
                  setShowAddon(false);
                  setFormData({
                    planName: "",
                    noOfSearches: "",
                    noOfUsers: "",
                    rate: "",
                    discount: "",
                    planType: "",
                    subscriptionId: "",
                  });
                }}
                className="nav-link SubscriptionTabbutton active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Subscription Plans
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => {
                  setShowPlans(false);
                  setShowAddon(false);
                  setFormData({
                    planName: "",
                    noOfSearches: "",
                    noOfUsers: "",
                    rate: "",
                    discount: "",
                    planType: "addon",
                    subscriptionId: "",
                  });
                }}
                className="nav-link SubscriptionTabbutton"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Pay By Search
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="planHeading">
                <div>
                  <h3>Annual Plans</h3>
                </div>
                <div style={{ display: "flex" }}>
                  {/* <Button onClick={()=>{setEditAnnual(p=>!p)}} style={{ marginRight: "22px", backgroundColor: "crimson" }}>Edit Plans</Button> */}
                  <button
                    className="button_shadow"
                    onClick={() => {
                      setShowPlans(true);
                      ref.current?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Add New Plan
                  </button>
                </div>
              </div>
              <div className="scrollableTabel autoHeight">
                {loading ? (
                  <Loader />
                ) : (
                  <Table style={{ minWidth: "100%" }} striped responsive1>
                    <thead>
                      <tr>
                        <th style={{ zIndex: "2" }}>Plan Name</th>
                        <th>User</th>
                        <th>Searches Per Year</th>
                        {/* <th>Duration</th> */}
                        <th style={{ textAlign: "right" }}>Bonus Searches</th>
                        <th style={{ textAlign: "right" }}>Price($USD)</th>
                        <th style={{ textAlign: "center" }}>Show/Hide</th>
                        {/* <th style={{ textAlign: "center" }}>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {annualPlans?.length > 0 &&
                        annualPlans.map((el, idx) => {
                          return (
                            <tr>
                              <td>{el?.plan_name}</td>
                              <td>{commaInNumber(el?.no_of_users)}</td>
                              <td>
                                {commaInNumber(el?.no_of_searches)}
                                <span>/ Year</span>
                              </td>
                              {/* <td>1 Year</td> */}
                              <td style={{ textAlign: "right" }}>
                                {el?.discount}
                              </td>
                              <td
                                style={{ textAlign: "right" }}
                                className="price"
                              >
                                {commaInNumber(el?.rate)}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="disanleEnable">
                                  <input
                                    type="checkbox"
                                    checked={el?.visibility}
                                  />
                                  <label
                                    id={el?.annual_plan_id}
                                    className={"annual"}
                                    onClick={
                                      loading
                                        ? null
                                        : () => {
                                            showHide(
                                              el?.annual_plan_id,
                                              "annual"
                                            );
                                          }
                                    }
                                  >
                                    onOff
                                  </label>
                                </div>
                                {/* <Link to="#" className="editPlan">Edit</Link> */}
                                {/* <Link to="#" className="deletePlan">Delete</Link> */}
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                                            <span style={{ cursor: "pointer" }} onClick={() => { deletePlan(el.annual_plan_id, 'annual') }}>
                                                                <FontAwesomeIcon icon={faTrashCan} color={"crimson"} size='2x' />
                                                            </span>
                                                        </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                )}
                {annualPlans?.length === 0 && !loading && <NoDataFound />}
              </div>

              <div className="planHeading">
                <div>
                  <h3>Monthly Plans</h3>
                </div>
                <div style={{ display: "flex" }}>
                  {/* <Button style={{ marginRight: "22px", backgroundColor: "crimson" }}>Edit Plans</Button> */}
                  <button
                    className="button_shadow"
                    onClick={() => {
                      setShowPlans(true);
                      ref.current?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Add New Plan
                  </button>
                </div>
              </div>
              <div className="scrollableTabel autoHeight">
                {loading ? (
                  <Loader />
                ) : (
                  <Table style={{ minWidth: "100%" }} striped responsive1>
                    <thead>
                      <tr>
                        <th style={{ zIndex: "2" }}>Plan Name</th>
                        <th>User</th>
                        <th>Searches Per Year</th>
                        {/* <th>Duration</th> */}
                        <th style={{ textAlign: "right" }}>Bonus Searches</th>
                        <th style={{ textAlign: "right" }}>Price($USD)</th>
                        <th style={{ textAlign: "center" }}>Show/Hide</th>
                        {/* <th style={{ textAlign: "center" }}>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {monthlyPlans?.length > 0 &&
                        monthlyPlans.map((el) => {
                          return (
                            <tr>
                              <td>{el?.plan_name}</td>
                              <td>{commaInNumber(el?.no_of_users)}</td>
                              <td>
                                {commaInNumber(el?.no_of_searches)}
                                <span>/ Month</span>
                              </td>
                              {/* <td>1 Month</td> */}
                              <td style={{ textAlign: "right" }}>
                                {el?.discount}
                              </td>
                              <td
                                style={{ textAlign: "right" }}
                                className="price"
                              >
                                {commaInNumber(el?.rate)}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="disanleEnable">
                                  <input
                                    checked={el?.visibility}
                                    type="checkbox"
                                  />
                                  <label
                                    id={el?.monthly_plan_id}
                                    className="monthly"
                                    onClick={
                                      loading
                                        ? null
                                        : () => {
                                            showHide(
                                              el?.monthly_plan_id,
                                              "monthly"
                                            );
                                          }
                                    }
                                  >
                                    onOff
                                  </label>
                                </div>
                                {/* <Link to="#" className="editPlan">Edit</Link> */}
                                {/* <Link to="#" className="deletePlan">Delete</Link> */}
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                                                <span style={{ cursor: "pointer" }} onClick={() => { deletePlan(el.monthly_plan_id, 'monthly') }}>
                                                                    <FontAwesomeIcon icon={faTrashCan} color={"crimson"} size='2x' />
                                                                </span>
                                                            </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                )}
                {monthlyPlans?.length === 0 && !loading && <NoDataFound />}
              </div>

              {
                // showPlans &&
                // <Form>
                //     <div ref={ref} style={{ fontWeight: "700", marginBottom: "11px" }}>Add New Plans</div>
                //     <div className='planCreate'>
                //         <select name="planType" onChange={handleChange}>
                //             <option style={{ backgroundColor: "lightgreen" }} name="planType" >Plan Type</option>
                //             <option name="planType" value={"annual"} >Annual</option>
                //             <option name="planType" value={"monthly"} >Monthly</option>
                //         </select>
                //         {/* <span style={{marginRight:"10px"}}>Plan for:</span>
                //     <input onChange={handleChange} type="radio" id="annual" name="fav_language" value="HTML" defaultChecked={formData.annual} />
                //     <label style={{ marginRight: "10px" }} for="annual">Annual</label>
                //     <input onChange={handleChange} type="radio" id="monthly" name="fav_language" value="CSS" defaultChecked={formData.monthly} />
                //     <label for="monthly">Monthly</label> */}
                //         <div className='row'>
                //             <div className="col-lg-12">
                //             </div>
                //             <div className="col-lg-6">
                //                 <Form.Group>
                //                     <Form.Label>Plan Name</Form.Label>
                //                     <Form.Control onChange={handleChange} name="planName" value={formData.planName} type="text" placeholder="Plan Name" checked={formData.annual} />
                //                 </Form.Group>
                //             </div>
                //             <div className="col-lg-6">
                //                 <Form.Group>
                //                     <Form.Label>No Of Users <span style={{ fontWeight: "350" }}>(in number)</span></Form.Label>
                //                     <Form.Control onChange={handleChange} name="noOfUsers" value={formData.noOfUsers} type="text" placeholder="No of users in number" checked={formData.monthly} />
                //                 </Form.Group>
                //             </div>
                //             <div className="col-lg-6">
                //                 <Form.Group>
                //                     <Form.Label>No of Searches <span style={{ fontWeight: "350" }}>(in number)</span></Form.Label>
                //                     <Form.Control onChange={handleChange} name="noOfSearches" value={formData.noOfSearches} type="text" placeholder="Search Limit in number" />
                //                 </Form.Group>
                //             </div>
                //             {/* <div className="col-lg-6">
                //             <Form.Group>
                //                 <Form.Label>Plan Id</Form.Label>
                //                 <Form.Control onChange={handleChange} name="subscriptionId" value={formData.duration} type="text" placeholder="plan id" />
                //             </Form.Group>
                //         </div> */}
                //             <div className="col-lg-6">
                //                 <Form.Group>
                //                     <Form.Label>Bonus Searches <span style={{ fontWeight: "350" }}>(in number)</span></Form.Label>
                //                     <Form.Control onChange={handleChange} name="discount" value={formData.discount} type="text" placeholder="Bonus searches in number" />
                //                 </Form.Group>
                //             </div>
                //             <div className="col-lg-6">
                //                 <Form.Group>
                //                     <Form.Label>Price ($USD)</Form.Label>
                //                     <Form.Control onChange={handleChange} name="rate" value={formData.rate} type="text" placeholder="Price in number" />
                //                 </Form.Group>
                //             </div>
                //             <div className="col-lg-12">
                //                 <Button onClick={addPlan} style={{ width: "200px", marginTop: "22px" }}>Add Plan</Button>
                //                 {/* <Form.Group>
                //                 <Form.Control type="submit" value="Add Plan" />
                //             </Form.Group> */}
                //             </div>
                //         </div>
                //     </div>
                // </Form>
              }
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div style={{ alignItems: "center", marginBottom: "22px" }}>
                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "7px",
                      padding: "5px 5px 5px 0px",
                      backgroundColor: "#1ea5d4",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <Button
                        style={{
                          fontWeight: "700",
                          backgroundColor: "#1ea5d4",
                          border: "0",
                          color: "azure",
                          cursor: "default",
                        }}
                      >
                        Pay By Search Plans
                      </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      {/* <Button style={{ backgroundColor: "red", marginRight: "22px" }}>Edit</Button> */}
                      <button
                        className="button_shadow"
                        datatype="orange"
                        onClick={() => {
                          setShowAddon(true);
                          ref.current?.scrollIntoView({ behavior: "smooth" });
                        }}
                        // style={{ backgroundColor: "#F16623" }}
                      >
                        Add New Plan
                      </button>
                    </div>
                  </div>
                  <div
                    className="scrollableTabel autoHeight"
                    style={{ position: "relative" }}
                  >
                    {loading ? (
                      <Loader />
                    ) : (
                      <table
                        style={{ textAlign: "center", width: "100%" }}
                        id="main-table"
                        className="colorTable table-striped sticky-table"
                      >
                        <thead
                          className="blue_td"
                          style={{ borderBottom: "1px solid #c4c4c4" }}
                        >
                          <td>No of searches</td>
                          <td style={{ textAlign: "right" }}>Price ($USD)</td>
                          <td>Show/Hide</td>
                          {/* <td>Action</td> */}
                        </thead>
                        <tbody>
                          {payBySearch?.length > 0 &&
                            payBySearch?.map((el, idx) => {
                              return (
                                <tr>
                                  <td>{commaInNumber(el?.no_of_searches)}</td>
                                  <td
                                    style={{ textAlign: "right" }}
                                    className="price"
                                  >
                                    {commaInNumber(el?.rate)}
                                  </td>
                                  <td>
                                    <div className="disanleEnable">
                                      <input
                                        checked={el?.visibility}
                                        type="checkbox"
                                        id={el?.payBySearch_id}
                                        className="pay-by-search"
                                      />
                                      <label
                                        onClick={
                                          loading
                                            ? null
                                            : () => {
                                                showHide(
                                                  el?.payBySearch_id,
                                                  "pay-by-search"
                                                );
                                              }
                                        }
                                      >
                                        onOff
                                      </label>
                                    </div>
                                  </td>
                                  {/* <td>
                                                                    <span style={{ cursor: "pointer" }} onClick={() => { deletePlan(el.payBySearch_id, 'pay-by-search') }}>
                                                                        <FontAwesomeIcon icon={faTrashCan} color={"crimson"} size='2x' />
                                                                    </span>
                                                                </td> */}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                    {payBySearch?.length === 0 && !loading && <NoDataFound />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={showPlans || showAddon}
        onHide={handleClose}
        className="coupon_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>Create New Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          {showPlans && (
            <Form>
              {/* <div ref={ref} style={{ fontWeight: "700", marginBottom: "11px" }}>Add New Plans</div> */}
              <div className="planCreate" style={{ marginBottom: "0px" }}>
                <select name="planType" onChange={handleChange}>
                  <option
                    style={{ backgroundColor: "lightgreen" }}
                    name="planType"
                  >
                    Plan Type
                  </option>
                  <option name="planType" value={"annual"}>
                    Annual
                  </option>
                  <option name="planType" value={"monthly"}>
                    Monthly
                  </option>
                </select>
                {/* <span style={{marginRight:"10px"}}>Plan for:</span>
                                    <input onChange={handleChange} type="radio" id="annual" name="fav_language" value="HTML" defaultChecked={formData.annual} />
                                    <label style={{ marginRight: "10px" }} for="annual">Annual</label>
                                    <input onChange={handleChange} type="radio" id="monthly" name="fav_language" value="CSS" defaultChecked={formData.monthly} />
                                    <label for="monthly">Monthly</label> */}
                <div className="row">
                  <div className="col-lg-12"></div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Plan Name</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="planName"
                        value={formData.planName}
                        type="text"
                        placeholder="Plan Name"
                        checked={formData.annual}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>
                        No Of Users{" "}
                        <span style={{ fontWeight: "350" }}>(in number)</span>
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="noOfUsers"
                        value={formData.noOfUsers}
                        type="text"
                        placeholder="No of users"
                        checked={formData.monthly}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>
                        No of Searches{" "}
                        <span style={{ fontWeight: "350" }}>(in number)</span>
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="noOfSearches"
                        value={formData.noOfSearches}
                        type="text"
                        placeholder="No of Searches"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="col-lg-6">
                                            <Form.Group>
                                                <Form.Label>Plan Id</Form.Label>
                                                <Form.Control onChange={handleChange} name="subscriptionId" value={formData.duration} type="text" placeholder="plan id" />
                                            </Form.Group>
                                        </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>
                        Bonus Searches{" "}
                        <span style={{ fontWeight: "350" }}>(in number)</span>
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="discount"
                        value={formData.discount}
                        type="text"
                        placeholder="Bonus searches"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Price ($USD)</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="rate"
                        value={formData.rate}
                        type="text"
                        placeholder="Price"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Button
                      className="button_shadow"
                      onClick={addPlan}
                      style={{ width: "100%", marginTop: "22px" }}
                    >
                      Add Plan
                    </Button>
                    {/* <Form.Group>
                                                <Form.Control type="submit" value="Add Plan" />
                                            </Form.Group> */}
                  </div>
                </div>
              </div>
            </Form>
          )}

          {showAddon && (
            <Form>
              <div
                ref={ref}
                className="planCreate"
                style={{ marginBottom: "0px", padding: "1.2rem" }}
              >
                {/* <h1>Create New Plan</h1> */}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="radio_row">
                      <label for="flexRadioDefault1">Plan For</label>
                      <div className="radioButton">
                        <input
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked
                        />
                        <label for="flexRadioDefault1">Pay By Search</label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                                        <Form.Group>
                                            <Form.Label>Plan Name</Form.Label>
                                            <Form.Control onChange={handleChange} name="planName" value={formData.planName} type="text" placeholder="Plan Name" checked={formData.annual} />
                                        </Form.Group>
                                    </div> */}
                  {/* <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>No Of Users</Form.Label>
                                    <Form.Control onChange={handleChange} name="noOfUsers" value={formData.noOfUsers} type="text" placeholder="User Limit" checked={formData.monthly} />
                                </Form.Group>
                            </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>
                        No of Searches{" "}
                        <span style={{ fontWeight: "350" }}>(in number)</span>
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="noOfSearches"
                        value={formData.noOfSearches}
                        type="text"
                        placeholder="No of Searches"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>Duration</Form.Label>
                                    <Form.Control onChange={handleChange} name="duration" value={formData.duration} type="text" placeholder="Duration" />
                                </Form.Group>
                            </div> */}
                  {/* <div className="col-lg-6">
                                        <Form.Group>
                                            <Form.Label>Discount(%) <span style={{ fontWeight: "350" }}>(in number)</span></Form.Label>
                                            <Form.Control onChange={handleChange} name="discount" value={formData.discount} type="text" placeholder="Discount" />
                                        </Form.Group>
                                    </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Price ($USD)</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="rate"
                        value={formData.rate}
                        type="text"
                        placeholder="Price"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Button
                      className="button_shadow"
                      onClick={addPlan}
                      style={{ width: "100%", marginTop: "22px" }}
                    >
                      Add Plan
                    </Button>
                    {/* <Form.Group>
                                    <Form.Control type="submit" value="Add Plan" />
                                </Form.Group> */}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
