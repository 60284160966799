import React from "react";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";

import TransactionHistory_icon from "../../assets/images/TransactionHistory_icon.png";
// import calender_icon from "../../assets/images/calender_icon.png"
// import time_icon from "../../assets/images/time_icon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import { useState } from "react";
import { Link } from "react-router-dom";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

const MyRecentTransactions = ({ history }) => {
  const st = useContext(myContext);
  const [recentTransactions, setRecenttransactions] = st.recentTransactions;
  // const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  // const [history, setHistory] = useState([]);
  // const temp = getSessionStorage();

  return (
    <div className="MyRecentTransactions_container">
      <div className="head">
        <span>My Recent Transactions</span>
        <Link to="/TransactionHistory">
          <Button className="TransactionHistoryButton">
            <img src={TransactionHistory_icon} loading="lazy" alt="" />
            Transaction History
          </Button>
        </Link>
      </div>
      <div className="bottom">
        <Table className="MyActivityTable2 dark_table" responsive striped>
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Purchase Type</th>
              <th>Length of Plan</th>
              <th>Duration</th>
              <th>No. of Searches</th>
              <th>Expires on</th>
              <th className="text-end">
                Amount({history[0]?.cur_code || "USD"})
              </th>
            </tr>
          </thead>
          <tbody>
            {recentTransactions?.length
              ? recentTransactions?.map((el, idx) => {
                  return (
                    idx < 5 && (
                      <tr>
                        {/* <td className='normal_text'><span className='red_text'><FontAwesomeIcon icon={faCalendarAlt} /></span> {el.purchase_date} | <span className='greenText'><FontAwesomeIcon icon={faClock} /></span></td> */}
                        <td className="normal_text">
                          <span className="red_text">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>{" "}
                          {el.purchase_date} |{" "}
                          <span
                            style={{ marginRight: "3px" }}
                            className="greenText"
                          >
                            <FontAwesomeIcon icon={faClock} />
                          </span>
                          {el?.purchase_time}
                        </td>
                        <td className="greenText bold_text">{el.plan_name}</td>
                        <td className="">{el.duration}</td>
                        <td className="">{el.duration}</td>
                        <td style={{ textAlign: "center" }} className="">
                          {commaInNumber(
                            el?.no_of_searches_with_coupon || el?.no_of_searches
                          )}
                        </td>
                        <td className="">
                          <span className="red_text">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>
                          {el.expiry_date}
                        </td>
                        <td align="right" className="">
                          {parseFloat(el.amount)?.toFixed(2)}
                        </td>
                      </tr>
                    )
                  );
                })
              : null}
          </tbody>
        </Table>
        {!recentTransactions?.length && (
          <h3 style={{ textAlign: "center" }}>No Transaction History!</h3>
        )}
      </div>
    </div>
  );
};

export default MyRecentTransactions;
