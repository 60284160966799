// import React, { useEffect, useState } from "react";




function getSessionStorage() {
    return JSON.parse(localStorage.getItem("user-data"));
}


export default getSessionStorage;