import React from "react";
import { Button } from "react-bootstrap";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { useState } from "react";
import logo_inner from "../../assets/images/logo_inner.png"
// import { useRef } from "react";
import Loader from "../Loader/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";



const btnStyle = {
    // backgroundColor: '#F16623',
    backgroundColor: "#134fae",
    color: "white",
    fontWeight: '600',
    border: 0,
    width: '121px',
    padding: "8px"
}



export default function SaveAsPDF({ table_id, len }) {
    const [load, setLoad] = useState(false)

    // const ref = useRef(null);

    // const key = [
    //     'Description',
    //     'Alternative Titles',
    //     'Education',
    //     'Knowledge',
    //     'Skills Applied',
    //     'Soft Skills',
    //     'Hard Skills',
    //     'Technology Tools',
    //     'Min Salary ($USD)',
    //     'Max Salary ($USD)',
    //     'Average Salary ($USD)'
    // ]


    function addFooter(doc) {
        var totalPages = doc.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            // doc.addImage(logo_inner, 'PNG', 50, 10, 75, 75);
            doc.autoTable({ startY: 100 })
            doc.setTextColor(100);
            doc.setFontSize(8);
            doc.text(50, doc.internal.pageSize.height - 30, 'illuminate.upskilltalent.com @All Rights Reserved');
        }

        return doc;
    }


    const exportPDF = () => {
        if (len === 0) { return }
        setLoad(true);
        // const len = profArr?.length;
        const unit = "pt";
        const size = 'a4'; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        // const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);


        // doc.setFontSize(15);
        doc.addImage(logo_inner, 'JPEG', 50, 10);
        autoTable(doc, {
            startY: 100, margin: 50,
            columnStyles: {
                0: { cellWidth: 80 },
                1: { cellWidth: (660 / len) },
                2: { cellWidth: (660 / len) },
                3: { cellWidth: (660 / len) },
                4: { cellWidth: (660 / len) },
                5: { cellWidth: (660 / len) },
                6: { cellWidth: (660 / len) }
            },
            html: `#${table_id}`,
            headStyles: { fillColor: "#0f4c62" }
        })
        const new_doc = addFooter(doc);
        new_doc.save('ILLUMINATE');
        setLoad(false);
        // doc.html(tableRef.current, {
        //     // Adjust your margins here (left, top, right ,bottom)
        //     margin: [40, 60, 40, 60],
        //     callback: function (pdf) {

        //         pdf.save('dataurlnewwindow');
        //     },
        // });

        // doc.html(tableRef.current, {
        //     async callback(doc) {
        //         margin: [40, 60, 40, 60],
        //             await doc.save('illuminate.pdf');
        //         setLoad(false)
        //     },
        // })
        // doc.save("illuminate.pdf");
    };

    return (<>
        <Button disabled={load} style={btnStyle} onClick={() => exportPDF()}>{load ? <Loader width={15} color={'white'} /> : <>< FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '8px' }} />Save as pdf</>}</Button>
    </>);
}