import React from "react";
import { Button, Modal } from "react-bootstrap";
import './style.css';
import { api } from '../../assets/API/api';
import axios from 'axios';
import { useContext } from "react";
import { myContext } from "../../App";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";






export default function CustomAlertForFavorites({ props }) {

    const st = useContext(myContext);
    const [userInfo, setUserInfo] = st.userInfo;
    const [activePlans, setActivePlans] = st.activePlans;
    const [popUp, setPopUp] = props.a;
    const [search_id, setSearch_id] = props.b;
    const [history, setHistory] = props.c;
    const id = props.d;
    const [arr, setArr] = props.e;
    const temp = getSessionStorage();


    const handleClose = () => { setPopUp(false) }

    const closePopUp = e => {
        e.preventDefault();
        console.log(e.target.className)
        if (e.target.className == "popup") {
            setPopUp(false);
        }
    }



    const removeFav = (search_id) => {

        if (activePlans?.length && activePlans[0].plan_name === "Premiere" || activePlans?.length && activePlans[0].plan_name === "Platinum") {
            axios.post(`${api}/markManagerFav?tokenMail=${temp?.email}`, { searchId: search_id })
                .then(res => {
                    // console.log(res.data);

                    // axios.post(`${api}/getSearchHistory`, { allHistory: true, email: userInfo.email || temp.email })
                    //     .then(res => {
                    //         // console.log(res.data);
                    //         setHistory(res.data);
                    //         setPopUp(false);
                    //         setArr(res.data)
                    //     })
                    //     .catch(err => console.log(err));

                    axios.post(`${api}/getSearchHistory`, { allHistory: true, fav: 1, email: userInfo.email || temp.email })
                        .then(res => {
                            // console.log(res.data);
                            setHistory(res.data);
                            setArr(res.data)
                            setPopUp(false);
                        })
                        .catch(err => console.log(err));
                })
                .catch(err => { console.log(err) })
            return;
        }

        axios.post(`${api}/markFavourite?tokenMail=${temp?.email}`, { searchId: search_id })
            .then(res => {
                console.log(res);
                axios.post(`${api}/getSearchHistory`, { id: id, fav: 1 })
                    .then(res => {
                        // console.log(res.data);
                        setHistory(res.data);
                        setPopUp(false);
                        setArr(res.data)
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err))
    }


    return (
        <Modal centered show={popUp} onHide={handleClose} size="md" className='coupon_modal'>
            <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
                <Modal.Title >Alert!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 style={{ textAlign: "center", marginBottom: "30px", fontWeight: "600", marginTop: "20px" }}>Are you sure to remove this item from Favorites?</h4>

                <div style={{ display: "flex", justifyContent: "center", gap: "30px", marginBottom: "20px" }}><Button style={{ backgroundColor: "green", border: "1px solid green" }} variant="primary" className="" onClick={() => { setPopUp(false) }} >NO</Button>
                    <Button style={{ backgroundColor: "red", border: "1px solid red" }} variant="primary" className="" onClick={() => { removeFav(search_id) }}>YES</Button></div>
            </Modal.Body>
        </Modal>
    )
}