import React from "react";
import { useHistory, Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// import { RotatingLines } from "react-loader-spinner";
// import back_icon from "../../assets/images/back_icon.png";
// import ProgressBar from 'react-bootstrap/ProgressBar';

// import print_icon from "../../assets/images/print_icon.png"
import view_details_icon from "../../assets/images/view_details_icon.png";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { api } from "../../assets/API/api";
import "../Subscription/style.css";
import getSessionStorage from "../../customHooks/getSessionStorage";
import "../Users/style.css";
import Loader, { LoaderTwo } from "../Loader/loader";
import BackArrow from "../backArrow/backArrow";
import { useDebouncedCallback } from "use-debounce";
// import { Modal } from 'react-bootstrap';

const getManagerUsedPoints = (arr, email) => {
  if (arr === []) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.email === email) {
      sum = sum + parseInt(arr[i]?.points);
    }
    // console.log('test', i)
  }
  return sum;
};

var arr = [];

const PreviousSearchResults = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [authenticate, setAuthenticate] = st.authentication;
  const [displayArr, setDisplayArr] = st.displayArr;
  const [searchParams, setSearchParams] = st.searchParams;
  const [search_id, setSearch_id] = st.search_id;
  const [history, setHistory] = useState([]);
  const [input, setInput] = useState("");
  const [year, setYear] = useState("All");
  const [users, setUsers] = useState([]);
  const [activePlans, setActivePlans] = st.activePlans;
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [myUsedPoints, setMyUsedPoints] = useState(0);
  // const [sideBarActive, setSideBarActive] = st.sideBar;
  const temp = getSessionStorage();

  let curYear = new Date();

  curYear = parseInt(curYear?.getFullYear());

  const filterYear = () => {
    let a = parseInt(temp?.date_of_joining?.slice(0, 4));
    let b = curYear;
    for (let i = a; i <= b; i++) {
      filter.push(i);
    }
    setFilter(filter);
  };

  let hist = useHistory();
  // console.log(hist.location.state);

  const getSearchHistory = async () => {
    setLoading(true);
    await axios
      .post(`${api}/getSearchHistory?tokenMail=${temp?.email}`, {
        id: hist.location.state?.id || userInfo?.id || temp?.user_id,
      })
      .then((res) => {
        // console.log(res.data);
        setHistory(res.data);
        arr = res.data;
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoading(false));
  };

  const getManagerHistory = async () => {
    setLoading(true);
    await axios
      .post(`${api}/getSearchHistory?tokenMail=${temp?.email}`, {
        allHistory: true,
        email: userInfo?.email || temp?.email,
      })
      .then((res) => {
        // console.log(res.data);
        setHistory(res.data);
        setMyUsedPoints(
          getManagerUsedPoints(res.data, temp?.email)?.toString()
        );
        arr = res.data;
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoading(false));
  };

  useEffect(() => {
    filterYear();

    window.scrollTo(0, 0);

    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }

    if (activePlans[0]?.no_of_users > 1) {
      if (hist.location.state?.id) {
        getSearchHistory();
      } else {
        getManagerHistory();
      }
    } else {
      getSearchHistory();
    }
  }, [activePlans]);

  function handleFav(search_id) {
    if (activePlans?.length && activePlans[0]?.no_of_users > 1) {
      axios
        .post(`${api}/markManagerFav?tokenMail=${temp?.email}`, {
          searchId: search_id,
        })
        .then((res) => {
          {
            // console.log(res.data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    // console.log('clicked')
    axios
      .post(`${api}/markFavourite?tokenMail=${temp?.email}`, {
        searchId: search_id,
      })
      .then((res) => {
        // console.log(res)
      })
      .catch((err) => console.log(err));
  }

  const handleChange = (val) => {
    // e.preventDefault();
    // let val = e.target.value;
    if (val == "") {
      setHistory(arr);
    }
    setHistory(
      arr.filter((ele) => {
        return (
          ele.primary_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.first_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.second_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.third_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.fourth_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.fifth_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.firstName?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.lastName?.toLowerCase().includes(val?.toLowerCase())
        );
      })
    );

    setInput(val);
  };

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      handleChange(value);
    },
    // delay in ms
    400
  );

  // const handleClick = (e) => {
  //     e.preventDefault();
  //     setHistory(arr.filter(ele => ele.primary_profession.toLowerCase().includes(input.toLowerCase())));
  // }

  const handleYearFilter = (e) => {
    e.preventDefault();
    setYear(e.target.value);

    if (e.target.value == "All") {
      setHistory(arr);
      return;
    }
    let val = e.target.value;
    setHistory(arr.filter((e) => e.date.includes(val)));
  };

  const isPointsUsed = (occupation, search_id, idx) => {
    if (!occupation) {
      return;
    }

    for (let i = idx + 1; i < history?.length; i++) {
      let occu = arr[i];
      if (!occu) {
        continue;
      }
      if (
        (occu.search_id !== search_id &&
          occupation === occu.primary_profession) ||
        occupation === occu.first_profession ||
        occupation === occu.second_profession ||
        occupation === occu.third_profession ||
        occupation === occu.fourth_profession ||
        occupation === occu.fifth_profession
      ) {
        return true;
      }
    }

    return false;
  };

  const view = async (
    primary,
    first,
    second,
    third,
    fourth,
    fifth,
    searchId
  ) => {
    if (!temp?.user_id) {
      return;
    }
    await setLoadingData(true);
    await setSearch_id(searchId);
    await localStorage.setItem("search-id", JSON.stringify(searchId));

    await setSearchParams({
      primaryProfession: "",
      firstProfession: "",
      secondProfession: "",
      thirdProfession: "",
      fourthProfession: "",
      fifthProfession: "",
    });

    await setSearchParams({
      primaryProfession: primary,
      firstProfession: first,
      secondProfession: second,
      thirdProfession: third,
      fourthProfession: fourth,
      fifthProfession: fifth,
    });

    await localStorage.setItem(
      "search-params",
      JSON.stringify({
        primaryProfession: primary,
        firstProfession: first,
        secondProfession: second,
        thirdProfession: third,
        fourthProfession: fourth,
        fifthProfession: fifth,
      })
    );

    await axios
      .post(`${api}/getResults?tokenMail=${temp?.email}`, {
        primaryProfession: primary,
        firstProfession: first,
        secondProfession: second,
        thirdProfession: third,
        fourthProfession: fourth,
        fifthProfession: fifth,
      })
      .then((res) => {
        // console.log(res.data)
        setDisplayArr(res.data);
        localStorage.setItem("main-array", JSON.stringify(res.data));
        hist.push("/SearchResults", hist.location.pathname);
      })
      .catch((err) => console.log(err))
      .finally((f) => setLoadingData(false));
  };

  // console.log(
  //   "history= ",
  //   history,
  //   activePlans[0]?.no_of_users,
  //   hist.location.state
  // );
  return (
    <Layout sideBarActive={1}>
      {loadingData && <LoaderTwo />}
      <div className="PreviousSearchResultsDiv">
        <div className="searchBar">
          <div style={{ display: "flex", gap: "1rem" }}>
            {/* <div
              className="left"
              style={{
                marginTop: "0.65rem",
                display: activePlans[0]?.no_of_users > 1 ? "block" : "none",
              }}
            >
              <Link
                to={`${hist.location.state?.prevUrl || "/DashboardOldUser"}`}
              >
                <img
                  // onClick={() =>
                  //   hist.push(`${hist.location.state || "/DashboardOldUser"}`)
                  // }
                  src={back_icon}
                  loading="lazy"
                  alt=""
                />
              </Link>
            </div> */}
            <BackArrow back={"/DashboardOldUser"} />
            <div className="left">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    onChange={(e) => {
                      debounced(e.target.value);
                    }}
                    type="text"
                    placeholder="Search by occupation"
                  />
                  <Button>Search</Button>
                </Form.Group>
              </Form>
            </div>
          </div>
          {activePlans[0]?.no_of_users > 1 ? null : (
            <div
              className="prev-charged-msg"
              style={{
                color: "green",
                padding: "12px 0",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              Previous charged searches are notated in green.
            </div>
          )}
          <div className="right">
            <select
              onChange={handleYearFilter}
              className="form-select"
              aria-label="Default select example"
            >
              <option selected value="All">
                All
              </option>
              {filter?.length
                ? filter.map((el) => {
                    return <option value={`${el}`}>{el}</option>;
                  })
                : null}

              {/* <option value="2021">2021</option>
                            <option value="2020">2020</option> */}
            </select>
          </div>
        </div>

        <div
          className="manager_used_points"
          id=""
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="" style={{ padding: "15px 0px" }}>
            <h3>{year}</h3>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              gap: "25px",
              justifyContent: "space-between",
            }}
          >
            <div className="new-search-prev">
              <Link to={"/NewSearch"}>
                <Button className="button_shadow" datatype="new-search">
                  New Search?
                </Button>
              </Link>
            </div>
            {activePlans[0]?.no_of_users > 1 && (
              <div id="usedPointsId">
                <Button
                  className="usedpointsbtn"
                  variant="primary"
                  style={{
                    backgroundColor: "#edfaff",
                    border: "solid 1px #c4c4c4",
                    color: "black",
                    cursor: "default",
                  }}
                >
                  Number of Units Used by Me :{" "}
                  {myUsedPoints || <Loader width={15} />}
                </Button>
              </div>
            )}
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <ul className="row result_box_ul load_animation">
            {!history?.length ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  marginTop: "10%",
                }}
              >
                No Data Found!
              </div>
            ) : (
              history.map((e, idx) => {
                return (
                  <li className="col-lg-6">
                    <div className="result_box">
                      <div className="head">
                        {/* <div className='left'>April 14<sup>th</sup></div> */}
                        <div className="left">{e.date?.slice(0, 10)}</div>
                        <div className="right plans2">
                          <label className="plan2 basic-plan2">
                            {activePlans[0]?.no_of_users > 1 ? (
                              <input
                                type="checkbox"
                                defaultChecked={e.manager_fav ? 1 : 0}
                                name="plan2"
                                id="letter"
                              />
                            ) : (
                              <input
                                type="checkbox"
                                defaultChecked={e.favourite ? 1 : 0}
                                name="plan2"
                                id="letter"
                              />
                            )}
                            <div
                              onClick={() => handleFav(e.search_id)}
                              className="plan-content2"
                            >
                              &nbsp;
                            </div>
                          </label>
                        </div>
                      </div>
                      {activePlans[0]?.no_of_users > 1 ? (
                        <div className="body_part row">
                          <div className="col-sm-6">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <ul className="contet_part">
                                  <li className="heading_text">
                                    Primary Search
                                  </li>
                                  <li>{e.primary_profession}</li>
                                </ul>
                              </div>
                              {activePlans[0]?.no_of_users > 1 ? (
                                <div>
                                  <ul className="contet_part">
                                    <li className="heading_text">
                                      Searched By
                                    </li>
                                    {/* <li>{users.filter(el => el.user_id == e.user_id).map(el => { return <span>{el.firstName} {el.lastName},  {el.role || "NA"}</span> })}</li> */}
                                    <li>
                                      {e.firstName === temp.firstName
                                        ? "Me"
                                        : hist.location.state
                                            ?.subordinateFirstName ||
                                          e.firstName}{" "}
                                      {e.lastName === temp.lastName
                                        ? null
                                        : hist.location.state
                                            ?.subordinateLastName || e.lastName}
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <ul className="contet_part">
                              <li className="heading_text">
                                Compared Searches
                              </li>
                              <li>{e.first_profession}</li>
                              <li>{e.second_profession}</li>
                              <li>{e.third_profession}</li>
                              <li>{e.fourth_profession}</li>
                              <li>{e.fifth_profession}</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="body_part row">
                          <div className="col-sm-6">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <ul className="contet_part">
                                  <li className="heading_text">
                                    Primary Search
                                  </li>
                                  <li
                                    style={{
                                      color: isPointsUsed(
                                        e.primary_profession,
                                        e.search_id,
                                        idx
                                      )
                                        ? "green"
                                        : "black",
                                    }}
                                  >
                                    {e.primary_profession}
                                  </li>
                                </ul>
                              </div>
                              {activePlans[0]?.no_of_users > 1 ? (
                                <div>
                                  <ul className="contet_part">
                                    <li className="heading_text">
                                      Searched By
                                    </li>
                                    {/* <li>{users.filter(el => el.user_id == e.user_id).map(el => { return <span>{el.firstName} {el.lastName},  {el.role || "NA"}</span> })}</li> */}
                                    <li>
                                      {e?.firstName === temp?.firstName
                                        ? "Me"
                                        : e?.firstName ||
                                          hist.location.state
                                            ?.subordinateFirstName}{" "}
                                      {e?.lastName === temp?.lastName
                                        ? null
                                        : e?.lastName ||
                                          hist.location.state
                                            ?.subordinateLastName}
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <ul className="contet_part">
                              <li className="heading_text">
                                Compared Searches
                              </li>
                              <li
                                style={{
                                  color: isPointsUsed(
                                    e.first_profession,
                                    e.search_id,
                                    idx
                                  )
                                    ? "green"
                                    : "black",
                                }}
                              >
                                {e.first_profession}
                              </li>
                              <li
                                style={{
                                  color: isPointsUsed(
                                    e.second_profession,
                                    e.search_id,
                                    idx
                                  )
                                    ? "green"
                                    : "black",
                                }}
                              >
                                {e.second_profession}
                              </li>
                              <li
                                style={{
                                  color: isPointsUsed(
                                    e.third_profession,
                                    e.search_id,
                                    idx
                                  )
                                    ? "green"
                                    : "black",
                                }}
                              >
                                {e.third_profession}
                              </li>
                              <li
                                style={{
                                  color: isPointsUsed(
                                    e.fourth_profession,
                                    e.search_id,
                                    idx
                                  )
                                    ? "green"
                                    : "black",
                                }}
                              >
                                {e.fourth_profession}
                              </li>
                              <li
                                style={{
                                  color: isPointsUsed(
                                    e.fifth_profession,
                                    e.search_id,
                                    idx
                                  )
                                    ? "green"
                                    : "black",
                                }}
                              >
                                {e.fifth_profession}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="button_Row"
                      >
                        {/* <Link to='/SearchResults'> */}
                        <Button
                          onClick={
                            loadingData
                              ? null
                              : () => {
                                  view(
                                    e.primary_profession,
                                    e.first_profession,
                                    e.second_profession,
                                    e.third_profession,
                                    e.fourth_profession,
                                    e.fifth_profession,
                                    e.search_id
                                  );
                                }
                          }
                          variant="primary"
                          className="view_details_icon_button"
                        >
                          <img src={view_details_icon} loading="lazy" alt="" />{" "}
                          View Details
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#1EA5D4",
                            border: "1px solid #1EA5D4",
                            padding: "7.5px",
                            cursor: "default",
                          }}
                        >
                          Searches Used: {e.points}
                        </Button>
                        {/* </Link> */}
                        {/* <Button variant="primary" className='print_icon_button'>
                                            <img src={print_icon} loading='lazy' alt='' /> Print
                                        </Button> */}
                      </div>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        )}
      </div>
    </Layout>
  );
};

export default PreviousSearchResults;
