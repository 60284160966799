import Button from "react-bootstrap/Button";
import React from "react";
// import TtrialBox from '../DashboardOldUser/TtrialBox'
import Layout from "../Layout/Layout";
import "../Subscription/style.css";
import Starter_icon from "../../assets/images/Starter_icon.png";
import Premiere_icon from "../../assets/images/Premiere_icon.png";
import Platinum_icon from "../../assets/images/Platinum_icon.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { api } from "../../assets/API/api";
import { useContext } from "react";
import { myContext } from "../../App";
import { RotatingLines } from "react-loader-spinner";
import getSessionStorage from "../../customHooks/getSessionStorage";

const noOfSearches = (x) => {
  // console.log(x);
  let sal = x.toString();
  let newSal = "";
  let c = 0;
  for (let i = sal.length - 1; i >= 0; i--) {
    c++;
    if (c % 3 === 0 && i !== 0) {
      newSal += sal[i];
      newSal += ",";
    } else newSal += sal[i];
  }
  let tempSal = newSal;
  newSal = "";
  for (let i = tempSal.length - 1; i >= 0; i--) {
    newSal += tempSal[i];
  }
  return newSal;
};

const Subscription = () => {
  const st = useContext(myContext);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [annualPlans, setAnnualPlans] = useState([]);
  const [payBySearch, setPayBySearch] = useState([]);
  const [ipData, setIpData] = st.ipData;
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = st.userInfo;
  // const [sideBarActive, setSideBarActive] = st.sideBar;
  const temp = getSessionStorage();

  const getAllSubscriptions = async () => {
    setLoading(true);
    await axios
      .get(`${api}/subscriptionPlans`)
      .then((res) => {
        // console.log(res.data);
        let data = res.data.data;
        setAnnualPlans(data.filter((el) => el.annual_plan_id));
        setMonthlyPlans(data.filter((el) => el.monthly_plan_id));
        setPayBySearch(data.filter((e) => e.payBySearch_id));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllSubscriptions();
  }, []);

  let hist = useHistory();

  const buyPlan = (e, el, idx) => {
    e.preventDefault();

    if (!temp) {
      alert("Please login/signup.");
      hist.push("/");
      return;
    }

    // console.log("plan details : ", el);
    // console.log(e.target.className);
    // console.log(payBySearch.filter(el => el.payBySearch_id ==e.target.id))
    if (e.target.name.includes("payBySearch")) {
      hist.push("/PaymentOptions", [payBySearch[idx]]);
      return;
    }
    if (e.target.name.includes("annualPlans")) {
      hist.push("/PaymentOptions", [annualPlans[idx]]);
      return;
    }
    if (e.target.name.includes("monthlyPlans")) {
      hist.push("/PaymentOptions", [monthlyPlans[idx]]);
      return;
    }
  };

  let sWidth = window.innerWidth;

  // console.log("pay by search", payBySearch);
  // console.log('screen width:', window.innerWidth);

  return (
    <Layout sideBarActive={6}>
      {/* <TtrialBox /> */}
      <div className="SubscriptionTab">
        <ul
          className="nav nav-tabs SubscriptionTabPlan"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link SubscriptionTabbutton active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Subscription Plans
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link SubscriptionTabbutton"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Pay By Search
            </button>
          </li>
        </ul>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <RotatingLines
              strokeColor="#0F4C62"
              strokeWidth="3"
              animationDuration="0.75"
              width="30"
              visible={true}
            />
          </div>
        ) : (
          <div className="tab-content  load_animation" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="annual_monthly_price">
                <div className="annual_monthly_price_top">
                  <ul className="nav nav-tabs " id="myTab2" role="tablist">
                    <li
                      className="nav-item annual_monthly_price_left"
                      role="presentation"
                    >
                      <button
                        className="nav-link active"
                        id="AnnualPrice-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#AnnualPrice"
                        type="button"
                        role="tab"
                        aria-controls="AnnualPrice"
                        aria-selected="true"
                      >
                        Annual Price
                      </button>
                    </li>
                    <li
                      className="nav-item annual_monthly_price_right"
                      role="presentation"
                    >
                      <button
                        className="nav-link "
                        id="MonthlyPrice-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#MonthlyPrice"
                        type="button"
                        role="tab"
                        aria-controls="MonthlyPrice"
                        aria-selected="false"
                      >
                        Monthly Price
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent2">
                    <div
                      className="tab-pane fade show active"
                      id="AnnualPrice"
                      role="tabpanel"
                      aria-labelledby="AnnualPrice-tab"
                    >
                      <ul className="AnnualPricePlan row">
                        {annualPlans.length &&
                          annualPlans.map((el, idx) => {
                            return (
                              <li
                                className={
                                  idx == 0
                                    ? "Starter"
                                    : idx == 1
                                    ? "Premiere"
                                    : "Platinum"
                                }
                              >
                                <div
                                  className={
                                    idx == 0
                                      ? "StarterDiv"
                                      : idx == 1
                                      ? "PremiereDiv"
                                      : "PlatinumDiv"
                                  }
                                >
                                  <ul>
                                    <li>
                                      <img
                                        src={
                                          idx == 0
                                            ? Starter_icon
                                            : idx == 1
                                            ? Premiere_icon
                                            : Platinum_icon
                                        }
                                        className=""
                                        loading="lazy"
                                        alt=""
                                      />
                                    </li>
                                    <li className="package">{el.plan_name}</li>
                                    <li className="packPrice">
                                      {el?.symbol} {el.rate?.toFixed(2)}/
                                      <span>year</span>
                                    </li>
                                    {/* {ipData?.exchange_rate && <li className='packPrice'>{ipData?.curr_code} {(parseFloat(el.rate) * ipData?.exchange_rate)?.toFixed(2)}/<span>year</span><span style={{ fontSize: "6px" }}></span></li>} */}
                                    <li className="discount">
                                      Bonus Searches: {el.discount}
                                    </li>
                                    <li className="user">
                                      {el.no_of_users}
                                      {el.no_of_users > 1 ? " Users" : " User"}
                                    </li>
                                    <li className="searches">
                                      {noOfSearches(
                                        parseInt(el.no_of_searches) +
                                          parseInt(el.discount)
                                      )}{" "}
                                      Searches / Year
                                    </li>
                                    <li className="plantButton">
                                      <Button
                                        name="annualPlans"
                                        onClick={(e) => {
                                          buyPlan(e, el, idx);
                                        }}
                                      >
                                        Upgrade Plan
                                      </Button>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="MonthlyPrice"
                      role="tabpanel"
                      aria-labelledby="MonthlyPrice-tab"
                    >
                      <ul className="AnnualPricePlan row">
                        {monthlyPlans.map((el, idx) => {
                          return (
                            <li
                              className={
                                idx == 0
                                  ? "Starter"
                                  : idx == 1
                                  ? "Premiere"
                                  : "Platinum"
                              }
                            >
                              <div
                                className={
                                  idx == 0
                                    ? "StarterDiv"
                                    : idx == 1
                                    ? "PremiereDiv"
                                    : "PlatinumDiv"
                                }
                              >
                                <ul>
                                  <li>
                                    <img
                                      src={
                                        idx == 0
                                          ? Starter_icon
                                          : idx == 1
                                          ? Premiere_icon
                                          : Platinum_icon
                                      }
                                      className=""
                                      loading="lazy"
                                      alt=""
                                    />
                                  </li>
                                  <li className="package">{el.plan_name}</li>
                                  <li className="packPrice">
                                    {el?.symbol} {el.rate?.toFixed(2)}/
                                    <span>month</span>
                                  </li>
                                  {/* {ipData.exchange_rate && <li className='packPrice'>{ipData.curr_code} {(parseFloat(el.rate) * ipData.exchange_rate)?.toFixed(2)}/<span>month</span><span style={{ fontSize: "6px" }}></span></li>} */}
                                  {/* <li className='discount'>Discount: {el.discount}%</li> */}
                                  <li className="user">
                                    {el.no_of_users}
                                    {el.no_of_users > 1 ? " Users" : " User"}
                                  </li>
                                  <li className="searches">
                                    {noOfSearches(el.no_of_searches)} Searches /
                                    Month
                                  </li>
                                  <li className="plantButton">
                                    <Button
                                      name="monthlyPlans"
                                      onClick={(e) => buyPlan(e, el, idx)}
                                    >
                                      Upgrade Plan
                                    </Button>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <ul className="PayBySearch">
                <li>
                  <ul className="PayBySearchItem">
                    <li className="head1">Number of Searches to purchase</li>
                    {payBySearch.map((el) => {
                      return <li>{noOfSearches(el.no_of_searches)}</li>;
                    })}
                  </ul>
                </li>
                <li>
                  <ul className="PayBySearchItem">
                    <li className="head2">
                      Price ({payBySearch[0]?.currency_code})
                    </li>
                    {payBySearch.map((el) => {
                      return (
                        <li>
                          <ul className="priceUL">
                            <li>
                              <ul>
                                <li
                                  style={{
                                    fontSize: sWidth < 500 ? "14px" : "16px",
                                  }}
                                  className="priceULPrice"
                                >
                                  <span style={{ marginRight: "5px" }}>
                                    {el.rate?.toFixed(2)}
                                  </span>
                                  {/* {ipData?.exchange_rate && <span style={{ marginRight: "5px" }} className=''>{(parseFloat(el.rate) * ipData?.exchange_rate)?.toFixed(2)}</span>} */}
                                  <span style={{ fontSize: "12px" }}>
                                    (
                                    {(
                                      parseInt(parseFloat(el.rate)) /
                                      parseInt(el.no_of_searches)
                                    ).toFixed(2)}
                                    /Search)
                                  </span>
                                </li>
                                {/* {ipData.exchange_rate && <li className='' style={{ fontWeight: "500", fontSize: "10px" }}>{ipData.curr_code} {(parseFloat(el.rate) * ipData.exchange_rate)?.toFixed(2)}/<span>Search</span><span style={{ fontSize: "6px" }}></span></li>} */}
                                {/* <li className='cutOff'>$9.90</li> */}
                              </ul>
                            </li>
                            {/* <li>
                                                        <div style={{ fontSize: "8px", backgroundColor: "white", color: "black", fontWeight: "300" }}>({(parseInt(el.rate) / parseInt(el.no_of_searches)).toFixed(2)}/Search)</div>
                                                    </li> */}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <ul className="PayBySearchItem">
                    <li className="head3">Actions</li>
                    {payBySearch.map((el, idx) => {
                      // console.log(el)
                      return (
                        <li>
                          <Button
                            id={el.payBySearch_id}
                            name="payBySearch"
                            onClick={(e) => {
                              buyPlan(e, el, idx);
                            }}
                          >
                            Buy
                          </Button>
                        </li>
                        // <li><Button style={{ backgroundColor: "gray" }} id={el.payBySearch_id} name='payBySearch' onClick={(e) => { alert('Coming Soon!') }}>Buy</Button></li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Subscription;
