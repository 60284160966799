import React, { useContext } from "react";
// import Layout from '../Layout/Layout'
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
// import { RotatingLines } from 'react-loader-spinner';
// import circle_icon from "../../assets/images/circle.png"
// import user_img_01 from "../../assets/images/user_img_01.png"
// import user_img_02 from "../../assets/images/user_img_02.png"
// import user_img_03 from "../../assets/images/user_img_03.png"
import no_image from "../../assets/images/no_image.png";
import { useState } from "react";
import { useEffect } from "react";
import "../Users/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import UploadProfileImage from "../UploadImage/UploadImage";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { api } from "../../assets/API/api";
import axios from "axios";
import { myContext } from "../../App";

let arr = [];

const UserList = ({ userList, points, myUsedPoints, getAllUsers }) => {
  const st = useContext(myContext);
  const [activePlans, setActivePlans] = st.activePlans;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [show, setShow] = useState(false);
  const [selectAll, setSlectAll] = useState(false);
  const temp = getSessionStorage();

  const handleClose = () => {
    setShow(false);
    setForm({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      designation: "",
    });
  };

  useEffect(() => {
    setForm({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      designation: "",
    });
  }, []);

  // useEffect(() => {
  //   setUsers(userList);
  //   arr = userList;
  // }, [userList]);

  useEffect(() => {
    let tempUsers = [...userList];
    for (let i = 0; i < tempUsers?.length; i++) {
      tempUsers[i].checked = false;
    }
    setUsers(tempUsers);
    arr = tempUsers;
  }, [userList]);

  const handleChange = (e) => {
    e.preventDefault();
    let val = e.target.value;

    if (val === "") {
      setUsers(arr);
      return;
    }
    setUsers(
      arr.filter(
        (el) =>
          el.firstName?.toLowerCase()?.includes(val?.toLowerCase()) ||
          el.lastName?.toLowerCase()?.includes(val?.toLowerCase()) ||
          el.email?.toLowerCase()?.includes(val?.toLowerCase()) ||
          el.role?.toLowerCase()?.includes(val?.toLowerCase())
      )
    );
  };

  const updateUser = (e) => {
    e.preventDefault();
    if (!form?.email || !form?.password) {
      return alert("Email or Password can not be blank!");
    }
    if (!temp?.user_id) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/updateUser/?tokenMail=${temp?.email}`, {
        ...form,
        B2B: true,
      })
      .then((res) => {
        // console.log(res.data);
        alert("Updated successfully!");
        getAllUsers();
        setShow(false);
      })
      .catch((err) => alert(err?.response?.data))
      .finally((f) => setLoading(false));
  };

  const changeForm = (e) => {
    let name = e.target.name;
    let val = e.target.value;

    setForm({
      ...form,
      [name]: val,
    });
  };

  const deleteUser = async (user) => {
    if (!temp?.user_id) {
      return;
    }

    setLoading(true);
    try {
      let ok = window.confirm("Are you sure to delete the user?");
      if (!ok) {
        return;
      }
      await axios.delete(
        `${api}/delete-user/?tokenMail=${temp?.email}&user_id=${user?.user_id}`
      );
      // alert('Deleted successfully!')
      getAllUsers();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheck = (idx) => {
    let pState = [...users];
    let pVal = pState[idx]?.checked;
    pState[idx].checked = !pVal;
    setUsers(pState);
  };

  const checkAll = (e) => {
    let pState = [...users];
    for (let i = 0; i < pState.length; i++) {
      const element = pState[i];
      if (selectAll) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    }
    setUsers(pState);
  };

  useEffect(() => {
    if (users?.length) {
      checkAll();
    }
  }, [selectAll]);

  const flushSubordinates = async () => {
    if (temp?.user_id && activePlans[0]?.no_of_users > 1) {
      if (users.filter((e) => !e?.checked)?.length === users?.length) {
        return alert("Please select some subordinates.");
      } else {
        let selectedUsers = users.filter((e) => e.checked);
        // console.log(selectedUsers);
        try {
          let ok = window.confirm(
            "Are you sure to flush the selected subordinates?"
          );
          if (!ok) {
            return;
          }
          setLoading(true);
          await axios.post(
            `${api}/flush-subordinates/?tokenMail=${temp?.email}`,
            { subordinates: selectedUsers, current_manager: temp?.email }
          );
          getAllUsers();
          setLoading(false);
          return alert("Subordinates flushed successfully!");
        } catch (err) {
          console.log(err);
          setLoading(false);
          return alert("Something went wrong!");
        }
      }
    }
  };
  // users.map(el => console.log(points?.get(el.user_id)))
  let hist = useHistory();

  // console.log("arr", users);
  return (
    // <Layout>
    <div>
      <div className="userListBox load_animation">
        <div id="userListId">
          <div className="search_area" style={{ paddingBottom: "0px" }}>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  onChange={handleChange}
                  type="text"
                  placeholder="Search by keyword"
                />
                <Button>Search</Button>
              </Form.Group>
            </Form>
          </div>
          <div
            style={{
              fontWeight: "600",
              padding: "10px",
              paddingRight: "11px",
              fontStyle: "italic",
              fontSize: "15px",
              textAlign: "right",
              // display: users?.filter((e) => e?.checked)?.length
              //   ? "block"
              //   : "none",
            }}
          >
            Selected Users: {users?.filter((e) => e?.checked)?.length}
          </div>
        </div>
        <div className="user-list-top-btns">
          <Button
            className="usedpointsbtn"
            variant="primary"
            style={{
              backgroundColor: "#1ea5d4",
              border: "solid 1px #1ea5d4",
              color: "white",
              cursor: "default",
              // fontSize: "9px",
              padding: "7px",
            }}
          >
            Number of current subordinates: {users?.length}
          </Button>
          <Button
            className="usedpointsbtn"
            variant="primary"
            style={{
              backgroundColor: "#1ea5d4",
              border: "solid 1px #1ea5d4",
              color: "white",
              cursor: "default",
              // fontSize: "9px",
              padding: "7px",
            }}
          >
            Number of accounts left:{" "}
            {activePlans[0]?.no_of_users - (users?.length + 1)}
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              flushSubordinates();
            }}
            className="button_shadow"
            datatype="new-search"
            style={{
              // fontSize: "12px",
              padding: "7px",
            }}
          >
            {loading ? "Exporting..." : "Export Subordinates"}
          </Button>
        </div>
        <div
          className="scrollableTabel autoHeight load_animation"
          style={{ marginTop: "0" }}
        >
          <Table
            className="user-list-table MyActivityTable2"
            datatype="user-list"
            style={{ minWidth: "100%" }}
            striped
            responsive1
            bordered
          >
            <thead>
              <tr>
                <th>
                  <input
                    onChange={() => {
                      setSlectAll((p) => !p);
                    }}
                    type="checkbox"
                    checked={selectAll}
                  />
                  <label style={{ marginLeft: "8px" }}>Select All</label>
                </th>
                <th></th>
                <th>Name</th>
                <th>Designation</th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Email ID
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Units Consumed
                </th>
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!users?.length ? (
                <h3 style={{ textAlign: "center" }}>No Data Found!</h3>
              ) : (
                users?.map((el, idx) => {
                  return (
                    <tr
                      style={{
                        boxShadow: "0px 0px 0px",
                      }}
                    >
                      <td>
                        <input
                          onChange={() => handleCheck(idx)}
                          type="checkbox"
                          checked={el?.checked}
                        />
                      </td>
                      <td className="fw-bold">
                        <div className="viewDiv">
                          <img
                            src={el.image_url || no_image}
                            className=""
                            loading="lazy"
                            alt=""
                          />
                        </div>
                      </td>
                      <td
                        style={{
                          fontWeight: "600",
                          color: "#F16623",
                        }}
                      >
                        {el.firstName || "NA"} {el.lastName || ""}
                      </td>
                      <td className="">{el.role || "NA"}</td>
                      <td className="fw-bold" style={{ textAlign: "center" }}>
                        <span style={{ color: "black" }}>{el.email}</span>
                      </td>
                      <td style={{ textAlign: "center" }}>{el?.points || 0}</td>
                      <td style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "15px",
                            // margin: "0 5px",
                          }}
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              hist.push("/PreviousSearchResults", {
                                id: el?.user_id,
                                prevUrl: "/AddUsers",
                                subordinateFirstName: el?.firstName,
                                subordinateLastName: el?.lastName,
                              });
                            }}
                            className="viewSpan"
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size="2x"
                              color="#1ea5d4"
                            />
                          </span>
                          <span
                            onClick={() => {
                              setForm({
                                ...form,
                                id: el.user_id,
                                firstName: el.firstName,
                                lastName: el.lastName,
                                email: el.email,
                                mobile: el.mobile,
                                password: el.password,
                              });
                              setShow(true);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={faPenSquare}
                              size="2x"
                              color="#1ea5d4"
                            />
                          </span>
                          {/* <span
                            onClick={
                              loading
                                ? null
                                : () => {
                                    deleteUser(el);
                                  }
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={"crimson"}
                              size="2x"
                            />
                          </span> */}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose} className="coupon_modal">
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>Update Subordinate</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <Form>
            <div className="planCreate" style={{ marginBottom: "0px" }}>
              {/* <h1>User Access Control</h1> */}

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      onChange={changeForm}
                      name="firstName"
                      value={form.firstName}
                      type="text"
                      placeholder="First Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      onChange={changeForm}
                      name="lastName"
                      value={form.lastName}
                      type="text"
                      placeholder="Last Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={changeForm}
                      name="email"
                      value={form.email}
                      type="Email"
                      placeholder="Email"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      onChange={changeForm}
                      name="password"
                      value={form.password}
                      type="text"
                      placeholder="Password"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    {/* <Form.Label>Profile Image</Form.Label> */}
                    <UploadProfileImage user_id={form.id} personal={false} />
                    {/* <Form.Control onChange={(e) => { setImage(e.target.files[0]) }} name="profile" type="file" placeholder="Profile Image" /> */}
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Button
                      className="button_shadow"
                      datatype="new-search"
                      disabled={loading}
                      onClick={loading ? null : updateUser}
                      style={{ width: "100%" }}
                    >
                      {loading ? "Updating..." : "Update"}
                    </Button>
                    {/* <Form.Control type="submit" value="Update" /> */}
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {}
    </div>
    // </Layout>
  );
};

export default UserList;
