import React from "react";
import Layout from "../Layout/Layout";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { Button, Table } from "react-bootstrap";
import Loader from "../Loader/loader";
import BackArrow from "../backArrow/backArrow";
import { useContext } from "react";
import { myContext } from "../../App";
import NoDataFound from "../NoDataFound/noDataFound";

export default function ExportedUsers() {
  const st = useContext(myContext);
  const [activePlans, setActivePlans] = st.activePlans;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();

  async function getExportedUsers() {
    try {
      if (!temp?.user_id) {
        return;
      }
      setLoading(true);
      const { data } = await axios.get(
        `${api}/get-exported-users/?tokenMail=${temp?.email}&email=${temp?.email}`
      );
      setUsers(
        data?.map((e) => {
          return { ...e, selected: false, show: true };
        })
      );
    } catch (e) {
      console.log(e);
      alert("Something went wrong!");
    }
    setLoading(false);
  }

  useEffect(() => {
    getExportedUsers();
  }, []);

  const handleFilter = (e) => {
    e.preventDefault();
    const { value } = e.target;

    if (!value) {
      setUsers(
        users?.map((e) => {
          return { ...e, show: true };
        })
      );
    } else {
      setUsers(
        users?.map((e) => {
          return e.email?.toLowerCase()?.includes(value?.toLowerCase())
            ? { ...e, show: true }
            : { ...e, show: false };
        })
      );
    }
  };

  const handleCheck = (idx) => {
    const temparr = [...users];
    temparr[idx].selected = !temparr[idx]?.selected;
    setUsers(temparr);
  };

  const addUsers = async (allEmails) => {
    if (!temp?.user_id) {
      return;
    }

    if (!activePlans?.length || activePlans[0]?.no_of_users <= 1) {
      alert("Please upgrade your plan to add users!");
      return;
    }

    if (
      activePlans?.length &&
      users?.length + allEmails?.length + 1 > activePlans[0]?.no_of_users
    ) {
      alert("You have reached max no. of allowed users for the current plan!");
      return;
    }

    try {
      if (!allEmails?.length) {
        return alert("Please select some users.");
      }
      let ok = window.confirm("Are you sure?");
      if (!ok) {
        return;
      }
      setLoading(true);
      const { data } = await axios.post(
        `${api}/add-users/?tokenMail=${temp?.email}`,
        {
          addedBy: temp?.email,
          allUsers: allEmails,
          managerFirstName: temp?.firstName,
          managerLastName: temp?.lastName,
        }
      );
      getExportedUsers();
      setLoading(false);

      // console.log(data);
      if (data?.rejectedMails?.length) {
        // setRejectedUsers(data.rejectedMails);
        alert("Failed to add some users.");
      } else {
        alert(
          "Users added successfully. Email invitations will be sent shortly!"
        );
      }
    } catch (error) {
      setLoading(false);
      return alert("Something went wrong.");
    }
  };

  // console.log(users);

  return (
    <Layout>
      <div className="exported-users-container box_with_shadow_padding">
        <div className="exported-users-top-box">
          <div>
            <BackArrow back={"/enterprise-menu"} />
            <span>
              No of selected users: {users?.filter((e) => e.selected)?.length}
            </span>
            <input
              type="text"
              placeholder="search by email"
              onChange={handleFilter}
            />
          </div>
          <div>
            <div>List of exported users</div>
            <Button
              onClick={() => {
                addUsers(users?.filter((e) => e.selected)?.map((e) => e.email));
              }}
              className="button_shadow"
              datatype="send-invite"
            >
              Add Back
            </Button>
          </div>
        </div>
        <div className="exported-users-table">
          <Table striped>
            <thead>
              <th>Select</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
            </thead>
            <tbody>
              {users?.length > 0 &&
                users?.map((u, idx) => {
                  return (
                    u?.show && (
                      <tr className="load_animation">
                        <td>
                          <input
                            type="checkbox"
                            checked={u?.selected}
                            onChange={() => handleCheck(idx)}
                          />
                        </td>
                        <td>
                          {u?.firstName || "NA"} {u?.lastName}
                        </td>
                        <td style={{ fontWeight: "500" }}>{u?.email}</td>
                        <td>{u?.mobile || "NA"}</td>
                      </tr>
                    )
                  );
                })}
            </tbody>
          </Table>
          {!loading && !users?.filter((e) => e.show)?.length && (
            <NoDataFound text={"No users found"} />
          )}
          {loading && <Loader color={"black"} />}
        </div>
      </div>
    </Layout>
  );
}
