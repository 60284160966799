import React from "react";
import Layout, { noOfDays } from "../Layout/Layout";

import TtrialBox from "./TtrialBox";
import WelcomeBox from "./WelcomeBox";
import MyActivityArea from "./MyActivityArea";
import MyRecentTransactions from "./MyRecentTransactions";
import MembershipStatus from "./MembershipStatus";
// import UpskillTalent from './UpskillTalent'
import { useContext } from "react";
import { myContext } from "../../App";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import AdminTableau from "../AdminTableau/AdminTableau";
import { useState } from "react";
import { api } from "../../assets/API/api";
import axios from "axios";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { Button } from "react-bootstrap";

// import getActivePlans from '../../customHooks/getActivePlans'
// import { getAddons } from '../../customHooks/getAddons'
// import { Modal } from 'react-bootstrap'
// import Loader from '../Loader/loader'
// import { getSideBar, setSideBar } from '../../customHooks/sideBar'

const DashboardOldUser = () => {
  const st = useContext(myContext);
  const [authenticate, setAuthenticate] = st.authentication;
  const [userInfo, setUserInfo] = st.userInfo;
  const [trial, setTrial] = st.trial;
  const [days, setDays] = st.days;
  const [activePlans, setActivePlans] = st.activePlans;
  const [addon, setAddon] = st.addon;
  // const [ipData, setIpData] = st.ipData;
  // const [sideBarActive, setSideBarActive] = st.sideBar;
  const [history, setHistory] = useState([]);
  const [myBalance, setMyBalance] = st.myBalance;
  const [usedPoints, setUsedPoints] = st.usedPoints;
  const [recentSearches, setRecentSearches] = st.recentSearches;
  const [recentFavSearches, setRecentFavSearches] = st.recentFavSearches;
  const [recentTransactions, setRecenttransactions] = st.recentTransactions;
  const [loading, setLoading] = useState(false);

  const temp = getSessionStorage();

  let hist = useHistory();

  const getUserData = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get(
        `${api}/me/?tokenMail=${temp?.email}&user_id=${temp?.user_id}&email=${temp?.email}`
      );
      const {
        user_data,
        active_plans,
        balance,
        used_points,
        recent_searches,
        recent_fav_searches,
        trial,
        recent_transactions,
        addons,
      } = data;

      // console.log(user_data);

      user_data &&
        setUserInfo({
          ...userInfo,
          id: user_data?.user_id,
          title: user_data?.title || "Mr.",
          email: user_data?.email,
          firstName: user_data?.firstName,
          lastName: user_data?.lastName,
          mobile: user_data?.mobile,
          dob: user_data?.dob,
          companyName: user_data?.companyName,
          yourRole: user_data?.role,
          companyWebsite: user_data?.companyWebsite,
          businessCategories: user_data?.businessCategories,
          address: user_data?.address,
          city: user_data?.city,
          state: user_data?.state,
          zipCode: user_data?.zipCode,
          country: user_data?.country,
          userType: user_data?.userType,
          highestQualification: user_data?.highestQualification,
          oldUser: true,
          ...user_data,
        });

      user_data &&
        localStorage.setItem("user-data", JSON.stringify(user_data));

      active_plans && setActivePlans([active_plans]);
      active_plans &&
        localStorage.setItem("active-plans", JSON.stringify([active_plans]));

      if (trial) {
        setDays(noOfDays(user_data?.date_of_joining));
        setMyBalance({
          subscription_balance: parseInt(balance?.trial_balance) || 0,
          addon_balance: 0,
        });
      } else {
        setMyBalance({
          subscription_balance: parseInt(balance?.subscription_balance) || 0,
          addon_balance: parseInt(balance?.addon_balance) || 0,
        });
      }

      used_points && setUsedPoints(used_points);

      recent_searches && setRecentSearches(recent_searches);

      recent_fav_searches && setRecentFavSearches(recent_fav_searches);

      recent_transactions && setRecenttransactions(recent_transactions);

      addons && setAddon(addons);

      setTrial(trial);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }
    await getUserData();
  }, []);

  if (!temp?.user_id) {
    return hist.push("/");
  }

  return (
    <Layout sideBarActive={0}>
      <div>
        {
          userInfo.userType === "Company Head" ||
          temp?.userType === "Company Head" ? (
            <div>
              <WelcomeBox />
              <div
                className="box_with_shadow_padding"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem 0",
                }}
              >
                <Button
                  className="button_shadow"
                  datatype="send-invite"
                  onClick={() => {
                    hist.push("/adimn-tableau");
                  }}
                >
                  See Dashboard
                </Button>
                {/* <AdminTableau /> */}
              </div>
            </div>
          ) : (
            // (userInfo.userType?.includes("Working Professional") ||
            //   temp?.userType?.includes("Working Professional") ||
            //   userInfo.userType == "Business Owner" ||
            //   temp?.userType == "Business Owner") ?
            <>
              {temp?.userType !== "enterprise-user" && trial ? (
                <TtrialBox days={days} />
              ) : null}
              <WelcomeBox />
              <MyActivityArea
                myBalance={myBalance}
                trial={trial}
                trHistory={recentTransactions}
              />
              {temp?.added_by?.includes("@") ? null : (
                <>
                  {temp?.userType === "enterprise-user" ? null : (
                    <MyRecentTransactions history={history} />
                  )}
                  <MembershipStatus />
                </>
              )}
              {/* <UpskillTalent /> */}
            </>
          )
          // : null
        }
      </div>
    </Layout>
  );
};

export default DashboardOldUser;
