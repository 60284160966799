import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import Layout from "../Layout/Layout";
import { api } from "../../assets/API/api";
import { RotatingLines } from "react-loader-spinner";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { useParams } from "react-router-dom";





export default function PaypalSubSuccess() {
    const [loading, setLoading] = useState(true);
    let temp = getSessionStorage();
    const queryParameters = new URLSearchParams(window.location.search);
    // console.log('PayerID ', queryParameters.get("PayerID"));
    const subscription_id = queryParameters.get("subscription_id");
    const user_id = queryParameters.get("user_id");
    const amount = queryParameters.get("amount");
    const no_of_searches = queryParameters.get("no_of_searches");
    const no_of_users = queryParameters.get("no_of_users");
    const duration = queryParameters.get("duration");
    const plan_name = queryParameters.get("plan_name");
    const currency_code = queryParameters.get("currency_code");
    const us_rate = queryParameters.get("us_rate");
    const no_of_searches_with_coupon = queryParameters.get("no_of_searches_with_coupon");
    const coupon = queryParameters.get('coupon');



    useEffect(() => {
        window.scrollTo(0, 0);
        axios.post(`${api}/confirm-paypal-subscription?tokenMail=${temp?.email}`, {
            subscription_id: subscription_id,
            user_id: user_id,
            amount: amount,
            no_of_searches: no_of_searches,
            no_of_users: no_of_users,
            duration: duration,
            plan_name: plan_name,
            currency_code: currency_code,
            us_rate: us_rate,
            no_of_searches_with_coupon: no_of_searches_with_coupon,
            coupon: coupon
        })
            .then(res => {
                // console.log(res.data);
                if (res.data === "payment-succeeded") {
                    setLoading(false);

                }
                else { alert("Payment Failed!"); }
            })
            .catch(e => {
                console.log(e);
                alert("Payment Failed!");
            })

    }, [])
    return (<>
        <Layout>
            {loading ?
                <div style={{ margin: "20%" }}>
                    <div style={{ display: "flex", justifyContent: "center", margin: "22px" }}>
                        <RotatingLines
                            strokeColor="#0F4C62"
                            strokeWidth="3"
                            animationDuration="0.75"
                            width="30"
                            visible={true}
                        />
                    </div>
                    <div style={{ textAlign: "center", fontStyle: "italic", fontWeight: "500" }}>Finalizing..</div>
                </div>
                :
                <div style={{ display: "flex", justifyContent: "center", margin: "23%", fontWeight: "600", color: "green" }}>
                    Congratulations! Your Payment Was Successfull!
                </div>}
        </Layout>
    </>)
}