import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";

import TrashFill from "../../assets/images/TrashFill.png";

import PhoneInput from "react-phone-number-input";

import { Button, Modal } from "react-bootstrap";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import { api } from "../../assets/API/api";
import axios from "axios";
import no_image from "../../assets/images/no_image.png";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import getSessionStorage from "../../customHooks/getSessionStorage";
import UploadProfileImage from "../UploadImage/UploadImage";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import getUserDetails from "../../customHooks/useFetch";
import Loader from "../Loader/loader";

const MyProfile = () => {
  const st = useContext(myContext);
  // const [userInfo, setUserInfo] = useState({});
  const [userInfo, setUserInfo] = st?.userInfo;
  const [authenticate, setAuthenticate] = st.authentication;
  const [value, setValue] = useState();
  const [profileImage, setProfileImage] = useState(null);
  const [trial, setTrial] = st.trial;
  const [days, setDays] = st.days;
  const [cVal, setCVal] = useState();
  const [resetPass, setResetPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();
  const [dualSpAl, setDualSpAl] = useState(false);

  useEffect(() => {
    setProfileImage(userInfo?.profileImage);
  }, [userInfo]);

  const getMyDetails = async () => {
    setLoading(true);
    let data = await getUserDetails(temp?.user_id, temp?.email);

    setValue(data.mobile);
    setCVal(options.find((el) => el.label === data.country));

    setUserInfo({
      ...userInfo,
      id: data?.user_id,
      title: data?.title || "Mr.",
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      dob: data.dob,
      companyName: data.companyName,
      yourRole: data.role,
      companyWebsite: data.companyWebsite,
      businessCategories: data.businessCategories,
      address: data.address,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      country: data.country,
      userType: data.userType,
      highestQualification: data.highestQualification,
    });

    setLoading(false);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);

    if (!temp?.user_id) {
      return;
    }
    getMyDetails();
    // getProfileImage();
  }, []);

  let hist = useHistory();

  const save = (e) => {
    e.preventDefault();

    if (userInfo?.email == "" || !temp?.email) {
      hist.push("/");
      return;
    }

    axios
      .post(`${api}/saveOtherDetails?tokenMail=${temp?.email}`, {
        id: userInfo.id || temp.user_id,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        mobile: userInfo.mobile,
        dob: userInfo.dob,
        highestQualification: userInfo.highestQualification,
        title: userInfo.title,
        email: userInfo.email,
        userType: userInfo.userType,
        companyName: userInfo.companyName,
        role: userInfo.yourRole,
        companyWebsite: userInfo.companyWebsite,
        businessCategories: userInfo.businessCategories,
        address: userInfo.address,
        city: userInfo.city,
        state: userInfo.state,
        zipCode: userInfo.zipCode,
        country: userInfo.country,
        oldUser: userInfo.oldUser,
      })
      .then((res) => {
        // console.log(res.data);
        localStorage.setItem(
          "user-data",
          JSON.stringify({ ...userInfo, ...temp, user_id: temp?.user_id })
        );
        setAuthenticate(true);
        alert("Profile Updated!");

        // hist.push('/DashboardOldUser');
      })
      .catch((err) => console.log(err));
  };

  const handleTitleChange = (e) => {
    setUserInfo({
      ...userInfo,
      title: e.target.value,
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  useEffect(() => {
    if (value) {
      setUserInfo({
        ...userInfo,
        mobile: value,
      });
    }
  }, []);

  const handleCountry = (value) => {
    setCVal(value);
    setUserInfo({
      ...userInfo,
      country: value?.label,
    });
  };

  const options = useMemo(() => countryList().getData(), []);

  const switchAccount = async () => {
    try {
      if (!temp?.user_id) {
        return;
      }
      let ok = window.confirm("Are you sure?");
      if (!ok) {
        return;
      }
      setLoading(true);
      await axios.post(`${api}/switch-account/?tokenMail=${temp?.email}`, {
        email: temp?.email,
        account_type: temp?.account_type,
      });
      localStorage.removeItem("profile");
      setDualSpAl(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return alert("Something went wrong!");
    }
  };

  const removeProfileImage = async () => {
    try {
      if (!temp?.user_id) {
        return;
      }
      let ok = window.confirm("Are you sure to remove your profile picture?");
      if (!ok) {
        return;
      }
      setLoading(true);
      await axios.post(`${api}/remove-profile-image`, {
        user_id: temp?.user_id,
      });
      // alert("Profile picture removed successfully.");
      localStorage.removeItem("profile");
      setUserInfo({ ...userInfo, profileImage: "" });
      setLoading(false);
    } catch (error) {
      alert("Something went wrong.");
      console.log(error);
      setLoading(false);
    }
  };

  console.log("user-data= ", profileImage);

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <Button
            onClick={() => {
              setUserInfo({ ...userInfo, addedBy: "" });
              localStorage.setItem('user-data',JSON.stringify({ ...temp, added_by: "" }));
            }}
          >
            Siwtch User?
          </Button> */}
          {resetPass && (
            <>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  className="button_shadow"
                  datatype="new-search"
                  // style={{
                  //   border: "1px solid #1EA5D4",
                  //   backgroundColor: "#edfaff",
                  //   color: "black",
                  //   fontWeight: "600",
                  //   boxShadow: "0px 0px 6px grey",
                  // }}
                  onClick={() => setResetPass(false)}
                >
                  Back
                </Button>
              </div>
              <ForgotPassword
                layout={true}
                email={userInfo?.email || temp?.email || ""}
              />
            </>
          )}

          <div
            className="load_animation"
            style={{ display: resetPass && "none" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: temp?.dual_account ? "space-between" : "right",
                marginBottom: "5px",
              }}
            >
              {temp?.dual_account ? (
                <Button
                  disabled={loading}
                  onClick={() => {
                    switchAccount();
                  }}
                  className="button_shadow"
                  datatype="new-search"
                >
                  {temp?.account_type === "individual"
                    ? "Personal Account"
                    : "Company Account"}
                </Button>
              ) : null}
              <Button
                className="button_shadow"
                datatype="new-search"
                onClick={() => {
                  setResetPass(true);
                }}
                // style={{
                //   border: "1px solid #1EA5D4",
                //   backgroundColor: "#edfaff",
                //   color: "black",
                //   fontWeight: "600",
                //   boxShadow: "0px 0px 6px grey",
                // }}
              >
                Reset Password?
              </Button>
            </div>
            <div className="my_profile_continer">
              <div className="myProfilePage">
                <div className="topPart">
                  <ul>
                    <li>
                      <img
                        style={{
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                        }}
                        src={profileImage || no_image}
                        className=""
                        loading="lazy"
                        alt="profile image"
                      />
                    </li>
                    <li>
                      <UploadProfileImage
                        profile={[profileImage, setProfileImage]}
                        user_id={temp?.user_id}
                        personal={true}
                      />
                      <div>
                        <Button
                          className="removeProfile"
                          style={{ boxShadow: "0px 0px 6px grey" }}
                          onClick={() => {
                            removeProfileImage();
                          }}
                        >
                          <img
                            src={TrashFill}
                            className=""
                            loading="lazy"
                            alt=""
                          />{" "}
                          Remove Profile Image
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
                <Form>
                  <div className="userInformation formDevider">
                    <h1>Basic Information</h1>

                    <div className="row mt-2">
                      <div className="radio_row">
                        <label
                          for="flexRadioDefault1"
                          className="radio_row_label"
                        >
                          Title
                        </label>
                        <div class="form-check">
                          <input
                            value="Mr."
                            onChange={handleTitleChange}
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={userInfo?.title === "Mr."}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Mr.
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            value="Ms."
                            onChange={handleTitleChange}
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={userInfo?.title === "Ms."}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Ms.
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            value="Dr."
                            onChange={handleTitleChange}
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={userInfo?.title === "Dr."}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Dr.
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          value={userInfo.firstName}
                          name="firstName"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          value={userInfo.lastName}
                          name="lastName"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formEmailID">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          value={userInfo.email}
                          name="email"
                          type="email"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formMobileNumber">
                        <Form.Label>Mobile Number</Form.Label>
                        {/* <Form.Control onChange={handleChange} value={userInfo.mobile} name="mobile" type="text" placeholder="" /> */}
                        <PhoneInput
                          defaultCountry="US"
                          international
                          withCountryCallingCode
                          value={value}
                          className="MobileNumberFlag"
                          onChange={setValue}
                        />
                      </Form.Group>
                    </div>
                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formEmailID">
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control
                          onChange={handleChange}
                          value={userInfo.dob}
                          name="dob"
                          type="date"
                          placeholder="dd-mm-yyyy"
                        />
                      </Form.Group>
                    </div>
                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formEmailID">
                        <Form.Label>Highest Qualification</Form.Label>
                        {/* <Form.Control onChange={handleChange} value={userInfo.highestQualification} name="highestQualification" type="text" placeholder="" /> */}
                        <select
                          onChange={handleChange}
                          name="highestQualification"
                          value={
                            userInfo.highestQualification ||
                            "Highest Qualification"
                          }
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option
                            selected
                            style={{
                              backgroundColor: "#003865",
                              color: "azure",
                            }}
                            value="Highest Qualification"
                          >
                            Highest Qualification
                          </option>
                          <option value="High School Diploma">
                            High School Diploma
                          </option>
                          {/* <option value="Graduation">Graduation</option> */}
                          <option value="Bachelor's Degree">
                            Bachelor's Degree
                          </option>
                          <option value="Master''s">Master's</option>
                          <option value="Advance Study">Advance Study</option>
                          <option value="PH.D.">PH.D.</option>
                          <option value="M.D.">M.D.</option>
                          <option value="E.eD.">E.eD.</option>
                        </select>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="userInfo formDevider">
                    <h1>Company Information</h1>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formCompanyName">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          value={userInfo.companyName}
                          onChange={handleChange}
                          name="companyName"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formYourRole">
                        <Form.Label>Your Role</Form.Label>
                        <Form.Control
                          value={userInfo.yourRole}
                          onChange={handleChange}
                          name="yourRole"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group
                        className="mb-3"
                        controlId="formCompanyWebsite"
                      >
                        <Form.Label>Company Website</Form.Label>
                        <Form.Control
                          value={userInfo.companyWebsite}
                          onChange={handleChange}
                          name="companyWebsite"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <div className="right">
                        <Form.Group
                          className="mb-1"
                          controlId="formBusinessCategories"
                        >
                          <Form.Label>Business Categories</Form.Label>
                        </Form.Group>
                        <select
                          onChange={handleChange}
                          name="businessCategories"
                          value={
                            userInfo.businessCategories || "Business Categories"
                          }
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option
                            style={{
                              backgroundColor: "#003865",
                              color: "azure",
                            }}
                            selected
                            value="Business Categories"
                          >
                            Business Categories
                          </option>
                          <option name="businessCategories" value="Automotive">
                            Automotive
                          </option>
                          <option
                            name="businessCategories"
                            value="Business Support & Supplies"
                          >
                            Business Support & Supplies
                          </option>
                          <option
                            name="businessCategories"
                            value="Computers & Electronics"
                          >
                            Computers & Electronics
                          </option>
                          <option
                            name="businessCategories"
                            value="Construction & Contractors"
                          >
                            Construction & Contractors
                          </option>
                          <option name="businessCategories" value="Education">
                            Education
                          </option>
                          <option
                            name="businessCategories"
                            value="Entertainment"
                          >
                            Entertainment
                          </option>
                          <option name="businessCategories" value="Engineering">
                            Engineering
                          </option>
                          <option
                            name="businessCategories"
                            value="Food & Dining"
                          >
                            Food & Dining
                          </option>
                          <option
                            name="businessCategories"
                            value="Health & Medicine"
                          >
                            Health & Medicine
                          </option>
                          <option
                            name="businessCategories"
                            value="Home & Garden"
                          >
                            Home & Garden
                          </option>
                          <option
                            name="businessCategories"
                            value="Legal & Financial"
                          >
                            Legal & Financial
                          </option>
                          <option
                            name="businessCategories"
                            value="Manufacturing, Wholesale, Distribution"
                          >
                            Manufacturing, Wholesale, Distribution
                          </option>
                          <option
                            name="businessCategories"
                            value="Merchants (Retail)"
                          >
                            Merchants (Retail)
                          </option>
                          <option
                            name="businessCategories"
                            value="Miscellaneous (Type in)"
                          >
                            Miscellaneous (Type in)
                          </option>
                          <option
                            name="businessCategories"
                            value="Personal Care & Services"
                          >
                            Personal Care & Services
                          </option>
                          <option name="businessCategories" value="Real Estate">
                            Real Estate
                          </option>
                          <option
                            name="businessCategories"
                            value="Transportation"
                          >
                            Transportation
                          </option>
                        </select>
                      </div>
                      {/* <Form.Group className="mb-3" controlId="formBusinessCategories">
                                    <Form.Label>Business Categories</Form.Label>
                                    <Form.Control
                                        value={userInfo.businessCategories}
                                        onChange={handleChange}
                                        name="businessCategories"
                                        type="email"
                                        placeholder="" />
                                </Form.Group> */}
                    </div>
                  </div>

                  <div className="userInformation formDevider">
                    <h1>Location Information</h1>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formAddress">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          value={userInfo.address}
                          onChange={handleChange}
                          name="address"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          value={userInfo.city}
                          onChange={handleChange}
                          name="city"
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formState">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          value={userInfo.state}
                          name="state"
                          onChange={handleChange}
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formZipCode">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          value={userInfo.zipCode}
                          name="zipCode"
                          onChange={handleChange}
                          type="text"
                          placeholder=""
                        />
                      </Form.Group>
                    </div>

                    <div className="row mt-2">
                      <Form.Group className="mb-3" controlId="formCountry">
                        <Form.Label>Country</Form.Label>
                        {/* <Form.Control
                                        value={userInfo.country}
                                        name="country"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="" /> */}

                        <Select
                          options={options}
                          value={cVal}
                          onChange={handleCountry}
                        />

                        {/* <PhoneInput
                                        defaultCountry="IN"
                                        international
                                        withCountryCallingCode
                                        className='MobileNumberFlag'
                                        // value={value}
                                        // className='MobileNumberFlag'
                                        // onChange={setValue} />
                                    /> */}
                      </Form.Group>
                    </div>
                    <div className="buttn_row">
                      {/* <Link to='/DashboardOldUser'> */}
                      <Button
                        onClick={save}
                        variant="primary"
                        type="submit"
                        className="button_shadow"
                        datatype="new-search"
                        style={{ boxShadow: "0px 0px 3px grey" }}
                      >
                        Save
                      </Button>
                      {/* </Link> */}
                      {/* {authenticate ? <Redirect to= '/DashboardOldUser' /> : <Redirect to =''/>} */}
                    </div>
                  </div>

                  {/* <div className='SubscriptionSettings formDevider'>
                        
                        <div className='row mt-2'>
                            <Form.Group className="mb-3" controlId="formChangePassword">
                                    <Form.Label>Change Password</Form.Label>
                                <div className='password_box'>
                                    <Form.Control type="email" placeholder="" />
                                    <Button variant="primary" type="submit">
                                        <img src={eye_icon} className="" loading="lazy" alt="" />
                                    </Button>
                                </div>
                            </Form.Group>
                        </div> 
                            
                    </div> */}

                  {trial && (
                    <div className="SubscriptionSettings formDevider">
                      <h1>Subscription Settings</h1>

                      <div className="row mt-2">
                        <p className="redText">
                          {5 - days} Days left for your trial
                        </p>
                        <p>
                          You are currently using the free trial.{" "}
                          <Link to="/Subscription">Upgrade</Link> to avail all
                          the amazing benefits
                        </p>
                      </div>
                    </div>
                  )}

                  {/* <div className='SavedCards formDevider'>
                            <h1><img src={credit_card_icon} className="" loading="lazy" alt="" /> Saved Cards</h1>

                        </div> */}
                </Form>
              </div>
            </div>
          </div>

          {/* <Modal size='xl' show={resetPass} onHide={() => { setResetPass(false) }} >
                <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
                    <Modal.Title style={{ color: "red" }}>Password Reset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <ForgotPassword />
                </Modal.Body>
            </Modal> */}
        </>
      )}

      <Modal show={dualSpAl}>
        <p
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "500",
            wordSpacing: "3px",
            marginTop: "1rem",
          }}
        >{`Switched to ${
          temp?.account_type === "company" ? "individual" : "company"
        } account!`}</p>
        {/* <p
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "500",
            wordSpacing: "3px",
          }}
        >{`Please sign in again!`}</p> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            margin: "1rem 0",
          }}
        >
          <Button
            className="button_shadow"
            datatype="send-invite"
            style={{ padding: "0 1rem" }}
            onClick={() => {
              hist.push("/DashboardOldUser");
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default MyProfile;
