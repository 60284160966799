import React from "react";
import back_icon from "../../assets/images/back_icon.png";
import { Link, useHistory } from "react-router-dom";

export default function BackArrow({ back }) {
  let hist = useHistory();
  return (
    <>
      <div className="left" style={{ display: "flex", alignItems: "center" }}>
        <Link to={`${back || hist.location.state || "/DashboardOldUser"}`}>
          <img
            // onClick={() =>
            //   hist.push(`${hist.location.state || "/DashboardOldUser"}`)
            // }
            src={back_icon}
            loading="lazy"
            alt=""
          />
        </Link>
      </div>
    </>
  );
}
