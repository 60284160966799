import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Layout from '../Layout/Layout';

export class Contact extends Component {
    static propTypes = {

    }

    render() {
        return (
            <Layout>
            <div>
                Contact us page
            </div>
            </Layout>
        )
    }
}

export default Contact
