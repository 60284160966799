import React from 'react'

import crosstrain_icon from "../../assets/images/crosstrain_icon.png"
import update_icon from "../../assets/images/update_icon.png"
import optimize_icon from "../../assets/images/optimize_icon.png"
import upskill_icon from "../../assets/images/upskill_icon.png"
import talent_icon from "../../assets/images/talent_icon.png"
import analysis_icon from "../../assets/images/analysis_icon.png"
// import check_box_icon from "../../assets/images/check_box_icon.png"
import globe_icon from "../../assets/images/globe_icon.png"
import local_area_network_icon from "../../assets/images/local_area_network_icon.png"
import Layout from '../Layout/Layout'
import '../Subscription/style.css'



const UpskillTalent = () => {
    return (
        <Layout sideBarActive={7}>
            <div style={{ marginTop: '-55px' }} className='UpskillTalent load_animation'>
                <h3>The Upskill Talent Dashboard</h3>
                <h2>Unlocking Today's Skills For Tomorrow's Jobs</h2>
                <ul className='row'>
                    <li className='col-md-6 col-lg-4'>
                        <div className='colorHeadBox blueHeadBox'>
                            <p className='image'><img src={crosstrain_icon} loading="lazy" alt="" /></p>
                            <p className='head'>Crosstrain</p>
                            <p className='text'>Crosstrain through Learning and Development until you can hire someone</p>
                        </div>
                    </li>
                    <li className='col-md-6 col-lg-4'>
                        <div className='colorHeadBox orangeHeadBox'>
                            <p className='image'><img src={update_icon} loading="lazy" alt="" /></p>
                            <p className='head'>Update existing jobs</p>
                            <p className='text'>Write precise job descriptions based upon trends</p>
                        </div>
                    </li>
                    <li className='col-md-6 col-lg-4'>
                        <div className='colorHeadBox greenHeadBox'>
                            <p className='image'><img src={optimize_icon} loading="lazy" alt="" /></p>
                            <p className='head'>Optimize Your Talent</p>
                            <p className='text'>Learn about how to best position employees while closing their skills gap</p>
                        </div>
                    </li>
                    <li className='col-md-6 col-lg-4'>
                        <div className='colorHeadBox redHeadBox'>
                            <p className='image'><img src={upskill_icon} loading="lazy" alt="" /></p>
                            <p className='head'>Upskill</p>
                            <p className='text'>You can’t improve your product unless you know what skills they lack</p>
                        </div>
                    </li>
                    <li className='col-md-6 col-lg-4'>
                        <div className='colorHeadBox blueDarkHeadBox'>
                            <p className='image'><img src={talent_icon} loading="lazy" alt="" /></p>
                            <p className='head'>Talent Optimization</p>
                            <p className='text'>Align skills to create new products, smarter employees</p>
                        </div>
                    </li>
                    <li className='col-md-6 col-lg-4'>
                        <div className='colorHeadBox blueLightHeadBox'>
                            <p className='image'><img src={analysis_icon} loading="lazy" alt="" /></p>
                            <p className='head'>Job Analysis is Important</p>
                            <p className='text'>To create trending jobs you have to possess the skills necessary for the future</p>
                        </div>
                    </li>
                </ul>
                <div className='UpskillTalentTwoCol'>
                    <ul className='UpskillTalentTwoColUl'>
                        <li style={{ width: "auto" }} className='left'>
                            <h2>Why we built the Upskill Talent Dashboard</h2>
                            <div>
                                <div><ul className='content'>
                                    <li><img src={globe_icon} loading="lazy" alt="" /></li>
                                    <li>
                                        <p className='head'>From leaders around the globe</p>
                                        <p className=''>Monster.com states that 1/3 of companies say the skills gap widened in 2021 while 87% of them found it difficult to secure qualified employees.</p>
                                    </li>
                                </ul></div>
                                <div><ul className='content'>
                                    <li><img src={local_area_network_icon} loading="lazy" alt="" /></li>
                                    <li>
                                        <p className='head'>To our local communities</p>
                                        <p>Avoid laying off employees if they can transfer jobs.</p>
                                        <p>Cross train until you can hire someone full time for the position.</p>
                                        <p>Finding a new job.</p>
                                        <p>Bridge the Academic to the Corporate world.</p>
                                        <p>Identify the needed skills to create a new product.</p>
                                    </li>
                                </ul></div>
                            </div>
                        </li>
                        {/* <li className='right'>
                            <h2>How the Upskill Talent Dashboard works</h2>
                            <ul>
                                <li>Takes one profession and compares it to five other professions.</li>
                                <li>Upskill Talent Dashboard’s algorithm compiles many open-source government, job affiliated organizations, and corporations that connect people and jobs.</li>
                                <li>Compares what the selected professions have in common.</li>
                                <li>Contrasts the differences between them.</li>
                                <li>Usable report that makes Human Resource personnel smarter for upskill and talent optimization.</li>
                                <li>Save the report.</li>
                                <li>Create a new one.</li>
                                <li>Click below for price and options.</li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export default UpskillTalent